<template>
  <div class="menu-admin">
    <div class="menu-admin--header">
      <router-link to="/">
        <img :src="logo" class="header--logo--image" />
      </router-link>
    </div>
    <div class="menu-admin--links">
      <router-link
        :class="{ 'menu-admin--links__item__active': active === 'Início' }"
        to="/admin"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">INÍCIO</Paragraph>
      </router-link>
      <router-link
        :class="{ 'menu-admin--links__item__active': active === 'Corpus' }"
        to="/admin/corpus"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">CORPUS</Paragraph>
      </router-link>
      <router-link
        :class="{ 'menu-admin--links__item__active': active === 'Arquivos' }"
        to="/admin/wordlists"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">ARQUIVOS</Paragraph>
      </router-link>
      <!-- VERIFICAR SE O USUÁRIO É ADMINISTRADOR PARA VER A ROTA DE USUÁRIOS -->
      <router-link
        v-if="getRole && getRole.includes('ADMIN')"
        :class="{ 'menu-admin--links__item__active': active === 'Usuários' }"
        to="/admin/users"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">USUÁRIOS</Paragraph>
      </router-link>
    </div>
    <div class="menu-admin--footer">
      <Paragraph class="footer--rights" size="20" weight="medium">
        ©2023 Todos os direitos reservados
      </Paragraph>
    </div>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'
import logo from '@/assets/images/logo.svg'

import { mapGetters } from 'vuex'

/** Componente padrão do menu lateral do admin */
export default {
  name: 'MenuAdmin',
  components: {
    Paragraph
  },
  computed: {
    ...mapGetters('user', ['getRole'])
  },
  data() {
    return {
      /** Imagem do logo */
      logo: logo
    }
  },
  props: {
    /** Link ativo */
    active: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-admin {
  background: $primary;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $gray;

  position: sticky;
  top: 0px;

  .paragraph {
    color: $white;
  }

  &--header {
    background: $white;
    padding: 25px 0px;

    img {
      margin: 0px 30px;
      max-width: 100%;
      display: block;

      @media screen and (max-width: 950px) {
        margin: 0px 10px;
      }
    }
  }

  &--links {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__item {
      padding: 20px;
      border-bottom: 1px solid $primaryOpacity;
      transition: 0.2s all;

      &:hover,
      &__active {
        color: $active;
        .paragraph {
          margin: 0px;
          color: $active;
        }
      }
    }
  }

  &--footer {
    padding: 20px 0px;
    text-align: center;
  }
}
</style>
