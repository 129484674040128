<template>
  <div :class="{ header__redimension: redimension }" class="header">
    <div class="header--navbar">
      <Button :radius="false" element="router-link" link="/admin">Admin</Button>
    </div>

    <div class="header--content">
      <!-- LOGO DO SITE -->
      <router-link to="/" class="header--logo">
        <img :src="logo" class="header--logo--image" />
      </router-link>

      <!-- TÍTULO -->
      <Heading size="20" weight="medium" class="header--title">
        <span class="header--title__text">Busca Conep</span>
        <vue-custom-tooltip
          label="Use * e ? como curinga para ampliar a pesquisa."
        >
          <font-awesome-icon
            class="header--title__info"
            icon="fa-solid fa-circle-info"
            size="xs"
          />
        </vue-custom-tooltip>
      </Heading>

      <div class="header--form">
        <!-- CAMPO DE TERMO -->
        <FormInput
          v-model="search"
          :iconSearch="true"
          :radius="true"
          class="header--form__term"
          placeholder="Digite o termo que você deseja buscar..."
          @search="searchTerm()"
        />
      </div>

      <!-- FILTRO AVANÇADO -->
      <FilterAdvanced
        :visible="true"
        :toggleButton="false"
        :context="context"
        :situation="situation"
        :dateBegin="dateBegin"
        :dateEnd="dateEnd"
        :corpus="corpus"
        :corpusValue="corpusValue"
        @changeContext="context = $event"
        @changeSituation="situation = $event"
        @changeDateBegin="dateBegin = $event"
        @changeDateEnd="dateEnd = $event"
        @changeCorpus="corpus = $event"
      />
    </div>

    <!-- IMAGEM DE FUNDO -->
    <div class="header--background">
      <img :src="background" class="header--background__image" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import FormInput from '@/components/FormInput'
import Heading from '@/components/Heading'
import FilterAdvanced from '@/components/FilterAdvanced'

import background from '@/assets/images/background.png'
import logo from '@/assets/images/logo.svg'

/** Componente padrão de cabeçalho */
export default {
  name: 'Header',

  components: {
    Button,
    FormInput,
    Heading,
    FilterAdvanced
  },

  /** Recebe os dados do componente pai */
  inject: ['searchData'],

  props: {
    /** Se deve ser redimensionado */
    redimension: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      /** Termo pesquisado */
      search: this.$route.query.search,

      /** Data inicial */
      dateBegin: this.$route.query.dateBegin ?? null,

      /** Data final */
      dateEnd: this.$route.query.dateEnd ?? null,

      /** Contexto buscado */
      context: this.$route.query.context ?? 20,

      /** Situação do documento */
      situation: this.$route.query.situation,

      /** Filtro de corpus */
      corpus: [],

      /** Valor filtrado do corpus pela URL */
      corpusValue: this.$route.query.corpus,

      /** Imagem de background */
      background: background,

      /** Imagem do logo */
      logo: logo
    }
  },

  created() {
    /** Verificar se a situação do documento é "vigente", "revogado", ou os dois (null) */
    if (![null, 'true', 'false'].includes(this.situation))
      this.situation = 'true'
  },

  methods: {
    /** Pesquisa os termos - Enviar a pesquisa para o componente pai */
    searchTerm() {
      /** Detectar se o termo está preenchido */
      if (this.search) {
        /** Verificar se o filtro está aberto */
        this.searchData(
          this.search,
          this.context.toString(),
          this.corpus.map((item) => item.value).join(),
          this.dateBegin,
          this.dateEnd,
          this.situation
        )
      } else {
        /** Exibir alerta */
        this.showAlert()
      }
    },

    /** Exibir alerta */
    showAlert() {
      this.$toast.warning('Preencha os campos para realizar uma pesquisa.')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 540px;
  width: 100%;
  position: relative;
  padding: 20px 25px 100px 25px;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 25px 20px;
  }

  &__redimension {
    height: auto;
    padding: 20px 25px 20px 25px;

    .header--logo {
      margin: 20px auto;

      @media screen and (max-width: 768px) {
        margin: 20px auto 30px auto;
      }
    }
  }

  &--logo {
    padding: 15px;
    border-radius: 20px;
    width: 200px;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px auto;

    &--image {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: -1;
    overflow: hidden;

    &__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-height: 100%;

      @media screen and (max-width: 768px) {
        left: 20%;
      }
    }
  }

  &--navbar {
    max-width: $maxWidth;
    margin: 0px auto;
    display: flex;
    flex-direction: row-reverse;
  }

  &--content {
    max-width: $maxWidth;
    margin: 0px auto;
  }

  &--title {
    display: inline-flex;
    align-items: center;
    gap: 5px;

    &__text {
      background: $primary;
      color: $white;
      border-radius: 50px;
      display: inline-block;
      padding: 7px 20px;
    }

    &__info {
      color: $primary;
      cursor: pointer;
    }
  }

  &--form {
    &__term {
      margin-bottom: 0px;
    }
  }
}
</style>
