export default ($axios) => ({
  /** Pesquisa os arquivos
   * @param { Object } data: "limit" e "term" para a consulta
   */
  search: (data) =>
    $axios.get('/search', {
      params: {
        ...data
      }
    }),

  /** Pesquisa os arquivos
   * @param { Object } data: "limit", "term" e "corpus" para a consulta
   */
  searchWithCorpus: (data) =>
    $axios.get('/search/corpus', {
      params: {
        ...data
      }
    }),

  /** Pesquisa os arquivos
   * @param { Object } data: "limit" e "term" para a consulta
   */
  searchFrequencyTop5: (data) =>
    $axios.get('/search/frequency/top5', {
      params: {
        ...data
      }
    }),

  /** Pesquisa os arquivos
   * @param { Object } data: "limit", "term" e "corpus" para a consulta
   */
  searchFrequencyTop5WithCorpus: (data) =>
    $axios.get('/search/frequency/top5/corpus', {
      params: {
        ...data
      }
    }),

  /** Traz os termos mais procurados (geral)
   * @param { Object } data: "limit" e "term" para a consulta
   */
  searchFrequency: (data) =>
    $axios.get('/search/frequency/all', {
      params: {
        ...data
      }
    }),

  /** Traz os termos mais procurados pelo usuário
   * @param { Object } data: "limit" e "term" para a consulta
   */
  searchFrequencyUser: (data) =>
    $axios.get('/search/frequency', {
      params: {
        ...data
      }
    }),

  /** Pesquisa os arquivos - downlaod
   * @param { Object } data: "limit" e "term" para a consulta
   */
  searchDownload: (data) =>
    $axios.get('/search/download', {
      params: {
        ...data
      }
    }),

  /** Pesquisa os arquivos - download
   * @param { Object } data: "limit", "term" e "corpus" para a consulta
   */
  searchWithCorpusDownload: (data) =>
    $axios.get('/search/corpus/download', {
      params: {
        ...data
      }
    })
})
