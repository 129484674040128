<template>
  <div class="home">
    <BaseLayout>
      <template #content>
        <!-- TÍTULO -->
        <Heading :divisor="true" size="5" weight="bold" class="home--title">
          <!-- Rotate -->
          <font-awesome-icon
            icon="fa-solid fa-circle-check"
            size="lg"
            class="home--icon"
          />
          <br />
          Termos mais buscados
        </Heading>
        <!-- TERMOS MAIS BUSCADOS -->
        <div class="home--terms">
          <Label
            v-for="(term, i) in terms"
            :key="i"
            :term="term"
            class="home--terms__item"
          ></Label>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout'
import Heading from '@/components/Heading'
import Label from '@/components/Label'

export default {
  name: 'Home',
  components: {
    BaseLayout,
    Heading,
    Label
  },
  data() {
    return {
      terms: null
    }
  },
  mounted() {
    /** Listar os termos mais pesquisados */
    this.getMostSearchedTerms()
  },
  methods: {
    /** Listar os termos mais pesquisados */
    getMostSearchedTerms() {
      this.$search
        .searchFrequency({ limit: 8 })
        .then((response) => {
          this.terms = response.data.map((item) => item.term)
        })
        .catch(function() {
          this.$toast.warning(
            'Houve um erro ao apresentar os termos mais pesquisados.'
          )
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  &--icon {
    margin-bottom: 10px;
  }

  &--title {
    text-align: center;
    color: $primary;
  }

  &--terms {
    margin-top: 40px;

    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__item {
      cursor: pointer;
      text-transform: capitalize;
    }
  }
}
</style>
