var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.element,_vm._b({tag:"component",staticClass:"button",class:[
    `button__${_vm.color}`,
    {
      button__active: _vm.active,
      button__disabled: _vm.disabled,
      button__radius: _vm.radius
    }
  ],attrs:{"href":_vm.element === 'a' ? _vm.link : false,"target":_vm.target},on:{"click":function($event){return _vm.$emit('click', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}},'component',{
    to: _vm.element === 'router-link' ? _vm.link : false
  },false),[_vm._t("default",function(){return [_vm._v(" Botão ")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }