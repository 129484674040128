<template>
  <div class="home">
    <AdminLayout page="Início">
      <template #content>
        <div class="home--header">
          <!-- IMAGEM DE FUNDO -->
          <img :src="background" class="home--background" />
          <!-- TÍTULO -->
          <div class="home--info">
            <div>
              <Heading class="home--title" weight="medium" size="80"
                >BUSCA CONEP</Heading
              >
              <Heading class="home--subtitle" weight="medium" size="20"
                >BEM-VINDO</Heading
              >
            </div>
            <div>
              <Button :radius="false" to="/" element="router-link"
                >IR PARA BUSCA CONEP</Button
              >
            </div>
          </div>
        </div>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Button from '@/components/Button'

import background from '@/assets/images/background-admin.png'

export default {
  name: 'Admin',
  components: {
    AdminLayout,
    Heading,
    Button
  },
  data() {
    return {
      /** Imagem de background */
      background: background
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  ::v-deep {
    .admin-layout--container {
      padding: 0px;
    }
  }

  &--header {
    height: 470px;
    position: relative;
    overflow: hidden;
    padding: 35px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  &--background {
    position: absolute;
    bottom: 0%;
    right: 0%;
    min-height: 100%;
    min-width: 100%;
    z-index: -1;
  }

  &--title {
    color: $white;
    margin-bottom: 5px;
  }

  &--subtitle {
    color: $white;
    margin-bottom: 0px;
  }

  &--info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
</style>
