/** ===================================
 * Helpers que manipulam arquivos Excel
 * ==================================== */
/**
 * Cria um arquivo csv a partir de uma string
 *
 * @param {String} data - Conteúdo do arquivo
 * @param {String} name - Nome do arquivo
 */

export const downloadCSV = (data, name = 'file') => {
  const csvData = new Blob(['\ufeff', data], {
    type: 'text/csv;charset=utf-8;'
  })

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, `${name}.csv`)
  } else {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(csvData)
    link.download = `${name}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
