<template>
  <div class="footer">
    <div class="footer--content">
      <Paragraph class="link" size="40" weight="bold">
        <span @click="openModal()">SOBRE</span>
      </Paragraph>

      <Paragraph size="40" weight="bold">
        ©2023 Todos os direitos reservados
      </Paragraph>
    </div>

    <!-- MODAL - SOBRE -->
    <Modal v-model="modalVisibility" title="Bem-vindo(a) ao Busca Conep">
      <Paragraph size="60">
        O Busca Conep é uma ferramenta/sistema/aplicação de uso livre e gratuito
        que permite a você localizar termos de seu interesse no conjunto de
        documentos que definem o funcionamento do Sistema CEP/Conep.
      </Paragraph>
      <Paragraph size="60">
        O Busca Conep, idealizado pela Comissão Nacional de Ética em Pesquisa
        (Conep), é fruto do Projeto Educação continuada dos Comitês de Ética em
        Pesquisa – EDUCA CEPs, desenvolvido em parceria com o Hospital Moinhos
        de Vento, no âmbito do Programa de Apoio ao Desenvolvimento
        Institucional do Sistema Único de Saúde (Proadi-SUS).
      </Paragraph>
      <Paragraph size="60">
        A ferramenta visa auxiliar pesquisadores(as), membros de CEP,
        participantes de pesquisa e demais interessados em melhor conhecer o
        arcabouço ético-regulatório da pesquisa envolvendo seres humanos no
        Brasil.
      </Paragraph>
      <Paragraph size="60">Instruções de uso:</Paragraph>
      <ul class="footer--list">
        <li>
          <Paragraph size="60">
            Após digitar no campo de busca e realizar a pesquisa do termo
            desejado, serão listadas as ocorrências da palavra pesquisada no
            conjunto de documentos (resolução, normas, cartas, ofícios etc.) do
            Sistema CEP/Conep.
          </Paragraph>
        </li>
        <li>
          <Paragraph size="60">
            O resultado da busca corresponderá à lista dos documentos que contém
            o termo ou a palavra-chave, apresentado no contexto do documento,
            pelo conjunto de palavras que antecede e sucede o termo buscado, no
            corpo do texto.
          </Paragraph>
        </li>
        <li>
          <Paragraph size="60">
            O contexto e a forma de apresentação da informação podem ser
            alterados para cada busca.
          </Paragraph>
        </li>
        <li>
          <Paragraph size="60">
            Caso você deseje, também é possível baixar/fazer o download do
            resultado da busca em seu computador.
          </Paragraph>
        </li>
      </ul>
      <Paragraph size="60">
        Observação: Após fechar este popup, estas informações poderão ser
        novamente acessadas clicando no item "Sobre".
      </Paragraph>
    </Modal>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'
import Modal from '@/components/Modal'

/** Componente padrão de rodapé */
export default {
  name: 'Footer',

  components: {
    Paragraph,
    Modal
  },

  data() {
    return {
      /** Visibilidade da modal de "sobre" */
      modalVisibility: false,

      /** Nome do campo no local storage */
      visibilityName: 'showInfoModal'
    }
  },

  created() {
    /** Verificar se a modal já foi exibida */
    if (!localStorage[this.visibilityName]) this.openModal()
  },

  methods: {
    /** Abrir modal sobre o sistema */
    openModal() {
      this.modalVisibility = true

      /** Registrar no localstorage */
      localStorage[this.visibilityName] = true
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 30px 25px;

  background: $secondary;

  &--content {
    max-width: $maxWidth;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &--list {
    list-style: disc;
    margin-left: 20px;
  }

  .paragraph {
    &.link {
      display: flex;
      align-items: center;
      gap: 5px;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 0px;
    }

    line-height: 18px;
  }
}
</style>
