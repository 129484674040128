<template>
  <div class="separate-line" :style="styleSeparateLine"></div>
</template>

<script>
/** Componente padrão de linhas */
export default {
  name: 'SeparatedLine',
  props: {
    /** Espaçamento para cima da linha. Padrão 40px */
    top: {
      type: [Number, String],
      default: 40
    },
    /** Espaçamento para direita da linha. Padrão 40px */
    right: {
      type: [Number, String],
      default: 40
    },
    /** Espaçamento para baixo da linha. Padrão 45px */
    bottom: {
      type: [Number, String],
      default: 45
    },
    /** Espaçamento para esquerda da linha. Padrão 40px */
    left: {
      type: [Number, String],
      default: 40
    }
  },
  data() {
    return {
      styleSeparateLine: {
        marginTop: `${this.top}px`,
        marginRight: `${this.right}px`,
        marginBottom: `${this.bottom}px`,
        marginLeft: `${this.left}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.separate-line {
  height: 4px;
  background: $secondary;
  border-radius: 10px;
}
</style>
