<template>
  <div class="home">
    <AdminLayout page="Arquivos">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE CORPUS -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput
            v-model="name"
            placeholder="Nome do Arquivo"
            class="form--input"
          />

          <!-- CAMPO DO CORPUS -->
          <FormSelect
            v-model="corpus"
            label="label"
            :options="corpusOptions"
            placeholder="Selecione o Corpus"
            class="form--input"
          />

          <!-- DATA -->
          <FormInput
            v-model="date"
            type="date"
            placeholder="Data de publicação"
            class="form--input"
          />

          <!-- CAMPO DO ARQUIVO -->
          <FormInputFile v-model="file" label="Arquivo" class="form--input" />

          <!-- BOTÃO DE ENVIAR -->
          <Button
            :radius="false"
            class="form--button"
            @click="createWordlist()"
          >
            CADASTRAR
          </Button>
        </div>

        <!-- STATUS DO DOCUMENTO -->
        <Heading size="10" weight="bold" class="title mb-2">Situação</Heading>

        <div class="form--group-radio">
          <!-- VIGENTE -->
          <FormInputRadio
            v-model="situation"
            :checked="situation"
            :inputValue="true"
            name="situation"
            label="Vigente"
            class="form--radio"
          />

          <!-- REGOGADOS -->
          <FormInputRadio
            v-model="situation"
            :checked="!situation"
            :inputValue="false"
            name="situation"
            label="Revogado (o documento perdeu vigência ou é documento de interesse histórico)"
            class="form--radio"
          />
        </div>

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :hasSearch="true"
          :hasFilter="true"
          :filterMultiple="true"
          :filterOptions="corpusOptions"
          filterPlaceholder="Filtrar por corpus"
          title="Arquivos cadastrados"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
          @filter="filterCallback"
        >
          <!-- WORDLIST -->
          <template v-slot:name="slotProps">
            <span
              class="datatable--link"
              @click="showWordlists(slotProps.row.id)"
            >
              <Paragraph size="60" weight="normal">
                {{ slotProps.value }}
              </Paragraph>
            </span>
          </template>

          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogWordlist(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>

        <!-- EXIBIR WORDLISTS -->
        <Modal
          v-model="showWordlist.visibility"
          :title="showWordlist.title"
          :description="showWordlist.description"
        ></Modal>

        <!-- EDITAR WORDLIST -->
        <Modal
          v-model="wordlist.edit"
          title="EDITAR ARQUIVO"
          class="modal-edit__edit"
        >
          <!-- NOME DO CORPUS -->
          <FormInput
            v-model="wordlist.name"
            :border="true"
            placeholder="Nome do Arquivo"
            class="modal-edit--input"
          />

          <!-- DATA -->
          <FormInput
            v-model="wordlist.date"
            :border="true"
            type="date"
            placeholder="Data de publicação"
            class="modal-edit--input"
          />

          <!-- STATUS DO DOCUMENTO -->
          <Heading size="10" weight="bold" class="title mb-3">Situação</Heading>

          <div class="form--group-radio">
            <!-- VIGENTE -->
            <FormInputRadio
              v-model="wordlist.situation"
              :checked="wordlist.situation"
              :inputValue="true"
              name="situation"
              label="Vigente"
              class="form--radio"
            />

            <!-- REGOGADOS -->
            <FormInputRadio
              v-model="wordlist.situation"
              :checked="!wordlist.situation"
              :inputValue="false"
              name="situation"
              label="Revogado (o documento perdeu vigência ou é documento de interesse histórico)"
              class="form--radio"
            />
          </div>

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveWordlist()"
            >
              GRAVAR
            </Button>
          </div>
        </Modal>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE WORDLIST -->
        <Modal
          v-model="wordlist.delete"
          title="REMOVER ARQUIVO"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o arquivo
            <strong>{{ wordlist.name }}?</strong>
          </Paragraph>
          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalWordlist()"
            >
              CANCELAR
            </Button>
            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteWordlist()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import FormInputFile from '@/components/FormInputFile'
import FormInputRadio from '@/components/FormInputRadio'
import FormSelect from '@/components/FormSelect'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

import { formatDate } from '@/helpers/date'

export default {
  name: 'WordlistsList',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    FormInputFile,
    FormInputRadio,
    FormSelect,
    Button,
    Datatable,
    Modal
  },

  data() {
    return {
      /** Nome do wordlist */
      name: null,

      /** Data do wordlist */
      date: null,

      /** ID do wordlist */
      corpus: null,

      /** Arquivo do wordlist */
      file: null,

      /** Situação do wordlist */
      situation: true,

      /** Lista de corpus para o select */
      corpusOptions: [],

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'name', alias: 'Arquivo', order: true },
          { name: 'corpus', alias: 'Corpus' },
          { name: 'situationLabel', alias: 'Situação' },
          { name: 'dateFormat', alias: 'Data' },
          { name: 'action', alias: 'Ação' }
        ],
        search: null,
        filter: null,
        data: [],
        numberElements: 0,
        numberPages: 0,
        page: 1,
        limitPerPage: 20,
        order: 'name',
        direction: 'asc'
      },

      /** Modal de visulização do wordlist */
      showWordlist: {
        visibility: false,
        title: null,
        description: null
      },

      /** Wordlist a ser editado */
      wordlist: {
        id: null /** ID do wordlist */,
        name: null /** Nome do wordlist */,
        situation: true /** Situação do wordlist */,
        date: null /** Data do wordlist */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Busca os corpus */
    this.getCorpus()

    /** Buscar os wordlist */
    this.getWordlist()
  },

  methods: {
    /** Buscar os wordlist */
    getWordlist() {
      /** Variável de services */
      let url = null

      /** Se tiver filtro por corpus */
      if (this.datatable.filter === null) {
        url = this.$files.getAll
      } else {
        url = this.$corpus.getById
      }

      url({
        name: this.datatable.search,
        direction: this.datatable.direction,
        limitPerPage: this.datatable.limitPerPage,
        orderBy: this.datatable.order,
        page: this.datatable.page,
        id: this.datatable.filter
      })
        .then((response) => {
          /** Pegar os dados pesquisados, se for filtrando por corpus ou não */
          let content = this.datatable.filter
            ? response.data.wordLists
            : response.data.content

          /** Pesquisar o wordlist (quando houver um corpus filtrado) */
          if (this.datatable.filter && this.datatable.search) {
            content = content.filter((wordlist) =>
              wordlist.name
                .toLowerCase()
                .includes(this.datatable.search.toLowerCase())
            )
          }

          /** Formatar os dados dos wordlists */
          const wordlists = content.map((wordlist) => {
            /** Situação do documento */
            const situationLabel = wordlist.valid
              ? 'Vigente'
              : wordlist.valid === false
              ? 'Revogado'
              : ''

            return {
              id: wordlist.id,
              name: wordlist.name,
              date: wordlist.date,
              dateFormat: wordlist.date ? formatDate(wordlist.date) : null,
              corpus: wordlist.corpus?.name,
              corpusId: wordlist.corpus?.id,
              situation: wordlist.valid,
              situationLabel: situationLabel
            }
          })

          /** Atualizar os dados da tabela */
          this.datatable.data = wordlists

          /** Dados da tabela */
          if (this.datatable.filter) {
            this.datatable.numberElements = wordlists.length
            this.datatable.numberPages = 0
          } else {
            this.datatable.numberElements = response.data.totalElements
            this.datatable.numberPages = response.data.totalPages
          }
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os arquivos.')
        })
    },

    /** Buscar os corpus para o select */
    getCorpus() {
      this.$corpus
        .getAll()
        .then((response) => {
          this.corpusOptions = response.data.map((corpus) => {
            return {
              id: corpus.id,
              label: corpus.name
            }
          })
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os corpus.')
        })
    },

    /** Criar wordlist */
    createWordlist() {
      /** Montando FormData para envio do wordlist */
      const fieldsWordlist = new FormData()
      fieldsWordlist.append('name', this.name)
      fieldsWordlist.append('date', this.date)
      fieldsWordlist.append('corpusId', this.corpus)
      fieldsWordlist.append('file', this.file)
      fieldsWordlist.append('valid', this.situation)

      this.$files
        .create(fieldsWordlist)
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Arquivo criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o Arquivo!')
        })
        .finally(() => {
          /** Limpar os campos de cadastro */
          this.name = null
          this.date = null
          this.corpus = null
          this.file = null
          this.situation = true
          /** Recarregar a lista */
          this.getWordlist()
        })
    },

    /** Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getWordlist()
    },

    /** Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getWordlist()
    },

    /** Pesquisa da tabela
     * @param {Object} search: Conteúdo pesquisado
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getWordlist()
    },

    /** Filtro da tabela
     * @param {Object} filter: Conteúdo filtrado
     */
    filterCallback(filter) {
      this.datatable.page = 1
      this.datatable.filter = filter ? filter : null
      this.getWordlist()
    },

    /** Exibir os arquivos do corpus
     * @param {Number} id: Id do corpus
     */
    showWordlists(id) {
      this.$files
        .get(id)
        .then((response) => {
          this.showWordlist.visibility = true
          this.showWordlist.title = response.data.name
          this.showWordlist.description = response.data.body
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao buscar o arquivo.')
        })
    },

    /** Exibir a modal de edição de corpus
     * @param {Number} id: Id do corpus
     */
    edit(id) {
      /** Localizar o wordlist na listagem */
      const wordlist = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do wordlist e abrir a modal */
      if (wordlist) {
        this.wordlist.edit = true
        this.wordlist.id = wordlist.id
        this.wordlist.name = wordlist.name
        this.wordlist.situation = wordlist.situation
        this.wordlist.date = wordlist.date
      } else {
        this.$toast.warning('Houve um erro ao encontrar o arquivo.')
        this.closeModalWordlist()
      }
    },

    /** Confirmar se deseja remover o wordlist
     * @param {Number} id: Id do wordlist
     */
    dialogWordlist(id) {
      /** Localizar o wordlist na listagem */
      const wordlist = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do corpus e abrir a modal de confirmação */
      if (wordlist) {
        this.wordlist.delete = true
        this.wordlist.id = wordlist.id
        this.wordlist.name = wordlist.name
        this.wordlist.situation = wordlist.situation
        this.wordlist.date = wordlist.date
      } else {
        this.$toast.warning('Houve um erro ao encontrar o arquivo.')
        this.closeModalWordlist()
      }
    },

    /** Fechar a modal de edição de corpus */
    closeModalWordlist() {
      this.wordlist.edit = false
      this.wordlist.delete = false
      this.wordlist.id = null
      this.wordlist.name = null
      this.wordlist.situation = true
      this.wordlist.date = null
      this.showWordlist.visibility = false
    },

    /** Salvar o wordlist */
    saveWordlist() {
      this.$files
        .update(this.wordlist.id, {
          id: this.wordlist.id,
          name: this.wordlist.name,
          valid: this.wordlist.situation,
          date: this.wordlist.date
        })
        .then(() => {
          this.$toast.success('Arquivo atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o arquivo.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalWordlist()
          /** Recarregar a listagem */
          this.getWordlist()
        })
    },

    /** Remover o corpus */
    deleteWordlist() {
      this.$files
        .delete(this.wordlist.id)
        .then(() => {
          this.$toast.success('Arquivo removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o arquivo.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalWordlist()
          /** Recarregar a listagem */
          this.getWordlist()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: center;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--group-radio {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  &--button {
    margin: 0px;
  }
}

.list-files {
  padding-left: 15px;
  &--item {
    line-height: 20px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 50px;
      text-align: center;
    }

    thead th:nth-child(5),
    tr td:nth-child(5) {
      width: 120px;
    }

    thead th:nth-child(6),
    tr td:nth-child(6) {
      width: 120px;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;

    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
