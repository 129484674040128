<template>
  <div class="login">
    <LoginLayout>
      <template #content>
        <div class="login--container">
          <form @submit="login">
            <!-- CAMPO DE E-MAIL -->
            <FormInput
              v-model="email"
              :border="true"
              label="Usuário"
              type="email"
              placeholder="Insira o e-mail"
              class="login--input"
            />

            <!-- CAMPO DE SENHA -->
            <FormInput
              v-model="password"
              :border="true"
              label="Senha"
              type="password"
              placeholder="Insira a senha"
              class="login--input"
            />

            <Paragraph v-if="message" size="40" class="login--message">
              {{ message }}
            </Paragraph>

            <!-- RECAPTCHA -->
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6Lcg7ywpAAAAADpDqMwHBooS7qcXMCbCq347iQYy"
              class="login--recaptcha"
              @verify="onVerifyRecaptcha"
            ></vue-recaptcha>

            <!-- BOTÃO DE LOGIN -->
            <Button
              :disabled="loading"
              :radius="false"
              class="login--button"
              type="submit"
            >
              ENTRAR
            </Button>
          </form>
        </div>
      </template>
    </LoginLayout>
  </div>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'

import { VueRecaptcha } from 'vue-recaptcha'

import {
  setCurrentUserAccess,
  deleteCookie,
  getAuthCookieName
} from '@/helpers/auth'

export default {
  name: 'Home',

  components: {
    LoginLayout,
    Paragraph,
    FormInput,
    Button,
    VueRecaptcha
  },

  data() {
    return {
      email: null /** E-mail */,
      password: null /** Senha */,
      message: null /** Mensagem de erro */,
      loading: false /** Se está carregando */,
      tokenRecaptcha: null /** Token de Recaptcha */
    }
  },

  created() {
    /** Exclui o cookie */
    deleteCookie(getAuthCookieName())

    /** Limpa o token da store */
    this.$store.dispatch('setAuthToken', null)

    /** Seta a flag de token expirado */
    this.$store.dispatch('setIsTokenExpired', true)
  },

  methods: {
    /**
     * Callback when reCAPTCHA is verified
     * @param {String} response - reCAPTCHA response token
     */
    async onVerifyRecaptcha(response) {
      this.tokenRecaptcha = response
    },

    /**
     * Login
     * @param {Event} e: Capturar evento do formulário de login
     */
    async login(e) {
      e.preventDefault()

      /** Verificar se o recaptcha foi ativado */
      if (!this.tokenRecaptcha) {
        this.$toast.warning('Preencha o Recaptcha para prosseguir.')
        return
      }

      /** Carregando loading */
      this.loading = true

      this.$auth
        .login({ email: this.email, password: this.password })
        .then((response) => {
          this.hideError()
          if (response.headers.authorization) {
            this.setAuthenticate(response.headers.authorization)
          } else {
            this.showError()
          }
        })
        .catch(() => {
          this.showError()
          this.$toast.warning('A senha inserida está incorreta.')
        })
        .finally(() => {
          /** Remover o loading */
          this.loading = false
        })
    },

    /**
     * Autenticar usuário e redirecionar para a Tela inicial
     * @param {String} token - Token de seis dígitos
     */
    setAuthenticate(token) {
      this.message = null
      /** Guardar token de acesso */
      setCurrentUserAccess(token)
      /** Redirecionar */
      window.location = '/admin'
    },

    /** Exibir os erros */
    showError() {
      this.$refs.recaptcha.reset()
      this.tokenRecaptcha = null
      this.message = 'O e-mail ou a senha estão incorretos'
      this.password = null
    },

    /** Esconde os erros */
    hideError() {
      this.message = null
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  &--button {
    display: block;
    margin-left: auto;
    width: 100%;
  }

  &--message {
    color: $error;
    text-align: center;
  }

  &--recaptcha {
    margin-bottom: 15px;
  }
}
</style>
