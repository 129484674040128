<template>
  <div class="form-file">
    <label
      :for="`form-file-${id}`"
      :class="{ 'form-file--label__placeholder': !value }"
      class="form-file--label"
    >
      <Paragraph size="60" weight="weight-normal">
        {{ fileName }}
      </Paragraph>
      <font-awesome-icon icon="fa-solid fa-file" size="lg" />
    </label>
    <input
      :id="`form-file-${id}`"
      :class="{
        'form-file--field__radius': radius,
        'form-file--field__border': border
      }"
      type="file"
      class="form-file--field"
      @change="onChange"
    />
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

export default {
  name: 'FormInputFile',
  components: {
    Paragraph
  },
  props: {
    /** Label do campo */
    label: {
      type: String,
      default: 'Arquivo'
    },
    /** Nome do campo */
    name: {
      type: String,
      default: ''
    },
    /** Valor do campo */
    value: {
      type: [String, File],
      default: null
    },
    /** Maior arredondamento do campo de input */
    radius: {
      type: Boolean,
      default: false
    },
    /** Se o input deve ter borda */
    border: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fileName() {
      return this.value instanceof File ? this.value.name : 'Arquivo'
    }
  },
  data() {
    return {
      id: this._uid /** Id único do componente */,
      file: null /** Arquivo importado */
    }
  },
  methods: {
    /** Quando houver uma alteração no campo de arquivo
     * @param {Event} e: Evento do input file
     */
    onChange(e) {
      /** Pegar o arquivo */
      const files = e.target.files || e.dataTransfer.files
      const reader = new FileReader()
      this.file = files[0]

      /** Validar tipo de arquivo */
      if (!this.isValidFile(this.file.name))
        return this.$toast.error('Formato de arquivo inválido!')

      /** Validar o tamanho do arquivo */
      const size = this.file.size / 1024
      if (size > 1000) return this.$toast.error('Arquivo muito grande!')

      /** Emit do arquivo */
      reader.onload = () => {
        this.$emit('input', this.file)
      }
      reader.readAsDataURL(this.file)
    },
    /** Verificar o tipo de arquivo
     * @param {File} filename: nome do arquivo
     */
    isValidFile(filename) {
      const ext = this.getExtension(filename)
      switch (ext.toLowerCase()) {
        case 'pdf':
        case 'txt':
          return true
      }
      return false
    },
    /** Pegar a extenção do arquivo
     * @param {String} filename: Nome do arquivo
     */
    getExtension(filename) {
      const parts = filename.split('.')
      return parts[parts.length - 1]
    }
  }
}
</script>

<style lang="scss" scoped>
.form-file {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;

  &--label {
    justify-content: space-between;
    min-height: 33px;
    display: flex;
    align-items: center;
    background: $white;
    padding: 8px 20px;
    color: $fontColor;
    width: 100%;
    border: 0;
    font-size: paragraph-size('paragraph-60');
    line-height: paragraph-size('paragraph-60');
    font-weight: font-weight('weight-normal');
    border-radius: 5px;
    cursor: pointer;

    .paragraph {
      margin-bottom: 0px;
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__radius {
      border-radius: 50px;
    }

    &__border {
      border: 1px solid $gray;
    }

    &__placeholder {
      filter: grayscale(100%);
      color: $fontColor;
      opacity: 0.7;
    }
  }

  &--field {
    opacity: 0;
    z-index: -10;
    position: absolute;
    display: none;
  }
}
</style>
