var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search"},[_c('TopProgress',{attrs:{"start":_vm.loading,"done":!_vm.loading}}),_c('BaseLayout',{on:{"updateSearch":function($event){return _vm.searchTerm($event, false, true)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"search--row"},[_c('Paragraph',{attrs:{"size":"60"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-magnifying-glass","size":"xs"}}),_vm._v(" Você buscou: "),_c('strong',[_vm._v(_vm._s(_vm.search))])],1),_c('div',{staticClass:"search--buttons-download"},[(
              _vm.$route.query.corpus &&
                _vm.$route.query.corpus.split(',').length === 1
            )?_c('Button',{attrs:{"radius":false,"element":"button"},on:{"click":function($event){return _vm.downloadCorpus()}}},[_c('font-awesome-icon',{staticClass:"datatable--icon",attrs:{"icon":"fa-solid fa-download"}}),_vm._v(" Documentos do corpus ")],1):_vm._e(),_c('Button',{attrs:{"radius":false,"element":"button"},on:{"click":function($event){return _vm.downloadExcel()}}},[_c('font-awesome-icon',{staticClass:"datatable--icon",attrs:{"icon":"fa-solid fa-download"}}),_vm._v(" Excel ")],1)],1)],1),_c('SeparatedLine',{attrs:{"top":"15","bottom":"15","left":"0","right":"0"}}),_c('Bar',{staticClass:"datatable--chart",class:{
          'datatable--chart__absolute': _vm.chartAbsolute,
          'datatable--chart__relative': !_vm.chartAbsolute
        },style:({ width: `${_vm.chartWidth}px` }),attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData,"height":160}}),_c('Datatable',{attrs:{"colsUpper":_vm.datatable.gridColumnsUpper,"colsNowrap":_vm.datatable.gridColumnNowrap,"colsLower":_vm.datatable.gridColumnsLower,"rows":_vm.formatDatatableRows,"numberElements":_vm.datatable.numberElements,"numberPages":_vm.datatable.numberPages,"page":_vm.datatable.page,"query":_vm.search,"changeVisibility":true,"loadedResults":true,"statusTable":_vm.datatable.statusTable,"loading":_vm.loading,"showPagination":false,"termResults":"resultados encontrados"},on:{"changeOrdering":_vm.orderCallback,"gridTable":_vm.changeStatusTable},scopedSlots:_vm._u([{key:"name",fn:function(slotProps){return [_c('span',{staticClass:"datatable--link",on:{"click":function($event){return _vm.openWordList(
                slotProps.row.wordlistId,
                slotProps.row.indexSearch
              )}}},[_c('Paragraph',{attrs:{"size":"60","weight":"normal"}},[_vm._v(" "+_vm._s(slotProps.value)+" ")])],1)]}},{key:"keyWord",fn:function(slotProps){return [_c('Paragraph',{attrs:{"size":"60","weight":"bold"}},[_vm._v(" "+_vm._s(slotProps.value)+" ")])]}},{key:"context",fn:function(slotProps){return [_c('Paragraph',{attrs:{"size":"60","weight":"normal"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.highlight(slotProps.value, _vm.search, 'datatable--highlight')
              )}})])]}}])}),_c('Modal',{attrs:{"title":_vm.modal.title,"description":_vm.modal.description,"indexSearch":_vm.modal.indexSearch,"query":_vm.search},model:{value:(_vm.modalVisibility),callback:function ($$v) {_vm.modalVisibility=$$v},expression:"modalVisibility"}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }