var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-input"},[(_vm.label)?_c('label',{staticClass:"form-input--label",attrs:{"for":`form-input-${_vm.id}`}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v(_vm._s(_vm.label))])],1):_vm._e(),(!_vm.hasMask)?_c('input',{ref:`form-input-${_vm.id}`,staticClass:"form-input--field",class:{
      'form-input--field__radius': _vm.radius,
      'form-input--field__border': _vm.border
    },attrs:{"placeholder":_vm.placeholder,"type":_vm.type,"id":`form-input-${_vm.id}`},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search()},"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur', $event)},"focus":function($event){return _vm.$emit('focus', $event)}}}):_vm._e(),(_vm.hasMask)?_c('the-mask',{ref:`form-input-${_vm.id}`,staticClass:"form-input--field",class:{
      'form-input--field__radius': _vm.radius,
      'form-input--field__border': _vm.border
    },attrs:{"masked":true,"mask":_vm.mask,"id":`form-input-${_vm.id}`,"type":_vm.type,"placeholder":_vm.placeholder,"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('search', _vm.value)},"input":function($event){return _vm.$emit('input', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"focus":function($event){return _vm.$emit('focus', $event)}}}):_vm._e(),(_vm.iconSearch)?_c('span',{staticClass:"form-input--search",on:{"click":function($event){return _vm.search()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-magnifying-glass","size":"lg"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }