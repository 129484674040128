import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const options = {
  position: 'bottom-left',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
}

Vue.use(Toast, options)

import Paginate from 'vuejs-paginate'
Vue.component('Paginate', Paginate)

import Services from '@/plugins/axios'

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import '@/assets/scss/global.scss'

/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add some free styles */
import {
  faCircleCheck,
  faMagnifyingGlass,
  faTableCells,
  faTableCellsLarge,
  faCircleXmark,
  faDownload,
  faCircleArrowDown,
  faCircleUser,
  faPenToSquare,
  faTrashCan,
  faFile,
  faSortDown,
  faRightFromBracket,
  faKey,
  faCircleInfo,
  faBars
} from '@fortawesome/free-solid-svg-icons'

/* add each imported icon to the library */
library.add(
  faCircleCheck,
  faMagnifyingGlass,
  faTableCells,
  faTableCellsLarge,
  faCircleXmark,
  faDownload,
  faCircleArrowDown,
  faCircleUser,
  faPenToSquare,
  faTrashCan,
  faFile,
  faSortDown,
  faRightFromBracket,
  faKey,
  faCircleInfo,
  faBars
)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Services)

import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
Vue.use(VueCustomTooltip, {
  name: 'VueCustomTooltip',
  color: '#000',
  background: '#fff',
  borderRadius: 100,
  fontWeight: 400
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
