<template>
  <div class="login-layout">
    <!-- CONTEÚDO -->
    <div class="login-layout--content">
      <!-- IMAGEM DE FUNDO -->
      <img :src="logo" class="login-layout--logo" />
      <!-- CONTEÚDO -->
      <slot name="content"></slot>
    </div>
    <!-- IMAGEM DE FUNDO -->
    <img :src="background" class="login-layout--background" />
  </div>
</template>

<script>
import background from '@/assets/images/background-login.png'
import logo from '@/assets/images/logo.svg'

export default {
  name: 'BaseLayout',
  data() {
    return {
      /** Imagem de background */
      background: background,
      /** Imagem do logo */
      logo: logo
    }
  }
}
</script>

<style lang="scss" scoped>
.login-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;

  &--content {
    margin: 0px auto;
    width: 100%;
    max-width: 375px;
    background: $white;
    border-radius: 10px;
    padding: 20px 25px;

    flex: 1;
  }

  &--background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    z-index: -1;
  }

  &--logo {
    margin: 0px auto 30px auto;
    display: block;
    max-width: 240px;
  }
}
</style>
