export default ($axios) => ({
  /** Busca um wordlist
   * @param {Number} id: Id do wordlist
   */
  get: (id = '') => $axios.get(`/wordlist/${id}`),

  /** Busca todos os wordlist
   * @param { Object } data: "direction" "limitPerPage" "name" "orderBy" e "page" para a consulta
   */
  getAll: (data) =>
    $axios.get('/wordlist/all', {
      params: {
        ...data
      }
    }),

  /** Pesquisa as wordlists
   * @param { Object } search: "direction" "limitPerPage" "name" "orderBy" e "page" para a consulta
   */
  search: (search) =>
    $axios.get('/wordlist/search', {
      params: {
        ...search
      }
    }),

  /** Remove um wordlist
   * @param {Number} id: Id do wordlist
   */
  delete: (id = '') => $axios.delete(`/wordlist/${id}`),

  /** Atualizar um corpus
   * @param { Number } id: "id" do corpus
   * @param { Object } data: "name" para editar um corpus
   */
  update: (id, data) =>
    $axios.put(`/wordlist/${id}`, {
      ...data
    }),

  /** Cria um wordlist
   * @param {Object} data
   */
  create: (data) =>
    $axios.post('/wordlist', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
})
