<template>
  <div class="search">
    <!-- BARRA DE PROGRESSO -->
    <TopProgress :start="loading" :done="!loading" />

    <BaseLayout @updateSearch="searchTerm($event, false, true)">
      <template #content>
        <div class="search--row">
          <!-- TERMO PESQUISADO -->
          <Paragraph size="60">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="xs" />
            Você buscou: <strong>{{ search }}</strong>
          </Paragraph>

          <div class="search--buttons-download">
            <!-- DOWNLOAD EM ZIP DOS ARQUIVOS DO CORPUS, APRESENTAR SE HOUVER UM CORPUS FILTRADO -->
            <Button
              v-if="
                $route.query.corpus &&
                  $route.query.corpus.split(',').length === 1
              "
              :radius="false"
              element="button"
              @click="downloadCorpus()"
            >
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-download"
              />
              Documentos do corpus
            </Button>

            <!-- DOWNLOAD EM EXCEL DOS DADOS DA PLANILHA -->
            <Button :radius="false" element="button" @click="downloadExcel()">
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-download"
              />
              Excel
            </Button>
          </div>
        </div>

        <!-- SEPARAÇÃO -->
        <SeparatedLine top="15" bottom="15" left="0" right="0" />

        <!-- GRÁFICO DE FREQUÊNCIA DOS ARQUIVOS -->
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :height="160"
          :class="{
            'datatable--chart__absolute': chartAbsolute,
            'datatable--chart__relative': !chartAbsolute
          }"
          :style="{ width: `${chartWidth}px` }"
          class="datatable--chart"
        />

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :colsNowrap="datatable.gridColumnNowrap"
          :colsLower="datatable.gridColumnsLower"
          :rows="formatDatatableRows"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :query="search"
          :changeVisibility="true"
          :loadedResults="true"
          :statusTable="datatable.statusTable"
          :loading="loading"
          :showPagination="false"
          termResults="resultados encontrados"
          @changeOrdering="orderCallback"
          @gridTable="changeStatusTable"
        >
          <!-- NOME DO ARQUIVO -->
          <template v-slot:name="slotProps">
            <span
              class="datatable--link"
              @click="
                openWordList(
                  slotProps.row.wordlistId,
                  slotProps.row.indexSearch
                )
              "
            >
              <Paragraph size="60" weight="normal">
                {{ slotProps.value }}
              </Paragraph>
            </span>
          </template>

          <!-- PALAVRA-CHAVE -->
          <template v-slot:keyWord="slotProps">
            <Paragraph size="60" weight="bold">
              {{ slotProps.value }}
            </Paragraph>
          </template>

          <!-- CONTEXTO -->
          <template v-slot:context="slotProps">
            <Paragraph size="60" weight="normal">
              <span
                v-html="
                  highlight(slotProps.value, search, 'datatable--highlight')
                "
              ></span>
            </Paragraph>
          </template>
        </Datatable>

        <!-- MODAL -->
        <Modal
          v-model="modalVisibility"
          :title="modal.title"
          :description="modal.description"
          :indexSearch="modal.indexSearch"
          :query="search"
        ></Modal>
      </template>
    </BaseLayout>
  </div>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout'
import Paragraph from '@/components/Paragraph'
import SeparatedLine from '@/components/SeparatedLine'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'
import TopProgress from '@/components/TopProgress'
import Button from '@/components/Button'
import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

import { highlight, removeTags } from '@/helpers/string'
import { downloadCSV } from '@/helpers/csv'

export default {
  name: 'Search',

  components: {
    BaseLayout,
    Paragraph,
    SeparatedLine,
    Datatable,
    Modal,
    TopProgress,
    Button,
    Bar
  },

  data() {
    return {
      /** Termo buscado */
      search: this.$route.query.search ?? null,

      /** Tabela */
      datatable: {
        statusTable: 'colsUpper',

        gridColumnsUpper: [
          { name: 'index', alias: '', order: true },
          {
            name: 'name',
            alias: 'Arquivo',
            style: { textAlign: 'center' },
            order: true
          },
          {
            name: 'contextLeft',
            alias: 'Contexto esquerda'
          },
          {
            name: 'keyWord',
            alias: 'Palavra-chave',
            style: { textAlign: 'center' }
          },
          { name: 'contextRight', alias: 'Contexto direita' }
        ],

        gridColumnNowrap: [
          { name: 'index', alias: '', order: true },
          {
            name: 'name',
            alias: 'Arquivo',
            style: { textAlign: 'center' },
            order: true
          },
          {
            name: 'contextLeft',
            class: 'nowrap',
            alias: 'Contexto esquerda',
            style: { textAlign: 'right', direction: 'rtl' }
          },
          {
            name: 'keyWord',
            alias: 'Palavra-chave',
            style: { textAlign: 'center' }
          },
          { name: 'contextRight', class: 'nowrap', alias: 'Contexto direita' }
        ],

        gridColumnsLower: [
          { name: 'index', alias: '', order: true },
          { name: 'name', alias: 'Arquivo', order: true },
          { name: 'context', alias: 'Contexto' }
        ],

        /** Elementos exibidos na tabela */
        data: [],

        /** Número de itens da consulta */
        numberElements: 0,

        /** Número de páginas totais */
        numberPages: 0,

        /** Página atual */
        page: 1,

        /** Limite de itens por página */
        limitPerPage: 100,

        /** Ordenação */
        order: 'index',

        /** Direção da ordenação */
        direction: 'asc'
      },

      /** Dados da modal */
      modal: {
        title: null,
        description: null,
        indexSearch: null
      },

      /** Visibilidade da modal */
      modalVisibility: false,

      /** Carregamento */
      loading: false,

      /** Listagem de corpus */
      corpusOptions: [],

      /** Dados do gráfico */
      chartData: {
        labels: [null, null, null, null, null],
        datasets: [
          {
            label: 'Docs com maiores ocorrências',
            backgroundColor: '#0a5290',
            data: []
          }
        ]
      },

      chartOptions: {
        indexAxis: 'y',
        responsive: true,
        horizontal: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              font: {
                size: 11,
                family: 'Roboto'
              }
            }
          }
        },
        scales: {
          y: {
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 11,
                family: 'Roboto'
              }
            }
          },
          x: {
            grid: {
              display: false
            }
          }
        }
      },

      /** Se o gráfico deve ficar posicionado ao lado da tabela */
      chartAbsolute: false,

      /** Largura do gráfico */
      chartWidth: 0
    }
  },

  computed: {
    /** Percorrer as tabelas e adicionar um index para a repetição do arquivo */
    formatDatatableRows() {
      const data = this.datatable.data

      return data.map((item, index) => {
        /** Se o nome do arquivo for igual ao anterior */
        item.indexSearch =
          data[index - 1] && item.name === data[index - 1].name
            ? data[index - 1].indexSearch + 1
            : 1

        return item
      })
    }
  },

  created() {
    /** Buscar as opções de corpus */
    this.getCorpus()

    /** Realizar pesquisa ao carregar a tela */
    this.searchTerm({})
  },

  mounted() {
    /** Detectar quando chegar no final do scroll do body */
    this.onScroll()

    this.getPaddingContent()

    window.addEventListener('resize', this.getPaddingContent)
  },

  methods: {
    /** Buscar as opções de corpus */
    getCorpus() {
      this.$corpus
        .getAll()
        .then((response) => {
          this.corpusOptions = response.data.map((corpus) => {
            return {
              value: corpus.id,
              label: corpus.name
            }
          })
        })
        .catch(() => {
          this.$toast.warning(
            'Houve um erro ao apresentar as opções de corpus.'
          )
        })
    },

    /** Download da planilha em excel dos dados */
    downloadExcel() {
      /** Carregamento */
      this.loading = true

      /** Atualizar os dados da pesquisa */
      const context = this.$route.query.context
      const dateBegin = this.$route.query.dateBegin
      const dateEnd = this.$route.query.dateEnd
      const corpus = this.$route.query.corpus
      const situation = this.$route.query.situation

      /** Se tiver corpus selecionados */
      const endpoint = corpus ? 'searchWithCorpusDownload' : 'searchDownload'

      this.$search[endpoint]({
        term: this.search,
        context: context ?? 20,
        corpus: corpus,
        dateBegin: dateBegin,
        dateEnd: dateEnd,
        valid: situation,
        orderBy: this.datatable.order,
        direction: this.datatable.direction
      })
        .then((response) => {
          downloadCSV(response.data, 'relatorio-busca-conep')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao realizar o download dos dados.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    /** Download dos documentos do corpus */
    downloadCorpus() {
      /** Pegar o corpus */
      const corpus = this.corpusOptions.find(
        (item) => item.value === parseInt(this.$route.query.corpus)
      )

      /** Verificar se o corpus é válido */
      if (!corpus || !parseInt(this.$route.query.corpus)) return

      this.$corpus
        .downloadAllFiles(corpus.value)
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', `${corpus.label}.zip`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao fazer o download dos arquivos!')
        })
    },

    /**
     * Pesquisar termo
     * @param {Object} data: Filtro com "search"
     * @param {Boolean} add: Se está adicionando mais itens da paginação
     * @param {Boolean} reset: Se deve redefinir a busca ou não
     */
    searchTerm(data, add = false, reset = false) {
      /** Carregamento */
      this.loading = true

      /** Atualizar os dados da pesquisa */
      this.search = Object.keys(data).length
        ? data.search
        : this.$route.query.search
      const context = Object.keys(data).length
        ? data.context
        : this.$route.query.context
      const dateBegin = Object.keys(data).length
        ? data.dateBegin
        : this.$route.query.dateBegin
      const dateEnd = Object.keys(data).length
        ? data.dateEnd
        : this.$route.query.dateEnd
      const corpus = Object.keys(data).length
        ? data.corpus
        : this.$route.query.corpus
      const situation = Object.keys(data).length
        ? data.situation
        : this.$route.query.situation

      /** Resetar a listagem */
      if (reset) {
        this.datatable.page = 1
        this.datatable.data = []
      }

      /** Resetar a ordenação */
      if (reset) {
        this.datatable.order = null
        this.datatable.direction = null
      }

      /** Se tiver corpus selecionados */
      const endpoint = corpus ? 'searchWithCorpus' : 'search'

      /** Pesquisar frequência top 5 */
      this.searchFrequencyData(
        this.search,
        context,
        corpus,
        dateBegin,
        dateEnd,
        situation
      )

      this.$search[endpoint]({
        term: this.search,
        context: context ?? 20,
        corpus: corpus,
        dateBegin: dateBegin,
        dateEnd: dateEnd,
        valid: situation,
        page: this.datatable.page,
        limitPerPage: this.datatable.limitPerPage,
        orderBy: this.datatable.order,
        direction: this.datatable.direction
      })
        .then((response) => {
          /** Número de elementos */
          this.datatable.numberElements = response.data.totalElements

          /** Número de páginas */
          this.datatable.numberPages = parseInt(response.data.totalPages)

          /** Formatar os dados da listagem */
          const results = response.data.content.map((item) => {
            return {
              index: item.index,
              keyWord: item.node,
              wordlistId: item.wordlist.id,
              name: item.wordlist.name,
              contextLeft: removeTags(item.left),
              contextRight: removeTags(item.right),
              situation: item.valid,
              context: removeTags(`${item.left} ${item.node} ${item.right}`)
            }
          })

          /** Verificar como deve atualizar a listagem */
          this.datatable.data = add
            ? [...this.datatable.data, ...results]
            : results
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os corpus.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    /**
     * Pesquisar frequência top 5
     * @param {String} term: Termo
     * @param {String|Number} context: Contexto
     * @param {String|Date} dateBegin: Data inicial
     * @param {String|Date} dateEnd: Data final
     * @param {String|Boolean} valid: Validade
     */
    searchFrequencyData(term, context, corpus, dateBegin, dateEnd, valid) {
      /** Se tiver corpus selecionados */
      const endpoint = corpus
        ? 'searchFrequencyTop5WithCorpus'
        : 'searchFrequencyTop5'

      this.$search[endpoint]({
        term: term,
        context: context ?? 20,
        corpus: corpus,
        dateBegin: dateBegin,
        dateEnd: dateEnd,
        valid: valid
      })
        .then((response) => {
          const data = response.data
          data.map((item, index) => {
            this.chartData.labels[index] = item.wordlist.name
            this.chartData.datasets[0].data.push(item.frequency)
          })
        })
        .catch(() => {
          this.$toast.warning(
            'Houve um erro ao apresentar os arquivos mais frequentes.'
          )
        })
    },

    /**
     * Mudar o status da tabela
     * @param {Number} status: Layout da tabela
     */
    changeStatusTable(status) {
      this.datatable.statusTable = status
    },

    /** Carregar mais itens da pesquisa quando chegar no final do scroll */
    loadMoreItems() {
      if (this.datatable.numberPages > this.datatable.page) {
        this.datatable.page++
        /** Nova busca e adicionar mais itens para a tabela */
        this.searchTerm({}, true)
      }
    },

    /** Detectar quando chegar no final do scroll do body */
    onScroll() {
      window.onscroll = () => {
        const bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >=
          document.documentElement.offsetHeight - 20

        if (bottomOfWindow && !this.loading && this.$route.name === 'Search') {
          /** Carregar mais itens na tabela */
          this.loadMoreItems()
        }
      }
    },

    /**
     * Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.page = 1
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.searchTerm({})
    },

    /**
     * Abrir modal
     * @param {Number} id: Identificação do wordlist
     * @param {Number|String} indexSearch: Index da palavra-chave dentro do texto
     */
    openWordList(id = 0, indexSearch = 1) {
      this.$files
        .get(id)
        .then((response) => {
          this.modalVisibility = true
          this.modal.title = response.data.name
          this.modal.description = response.data.body
          this.modal.indexSearch = indexSearch
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao buscar o arquivo.')
        })
    },

    highlight,

    /** Pegar a largura do padding do conteúdo da tela e redimensionar o canvas */
    getPaddingContent() {
      const widthFullScreen = document.body.offsetWidth

      /** Se for maior que 1300 */
      if (widthFullScreen > 1250) {
        this.chartAbsolute = true
        this.chartWidth = (widthFullScreen - 880) / 2
      } else {
        this.chartAbsolute = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  &--row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    p {
      margin-bottom: 0px;
    }
  }

  &--buttons-download {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.datatable {
  &--chart {
    border: 1px solid $gray;
    padding: 5px;
    left: 0px;

    &__absolute {
      position: absolute;
      margin: 0px 5px;
    }

    &__relative {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }

  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    .datatable--highlight {
      background: $tertiary;
    }

    thead th:nth-child(1),
    tr td:nth-child(1) {
      min-width: 50px;
    }

    thead th:nth-child(2),
    tr td:nth-child(2) {
      min-width: 180px;
    }
  }
}
</style>
