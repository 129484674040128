<template>
  <component
    :is="element"
    :class="`paragraph paragraph__size-${size} paragraph__weight-${weight}`"
  >
    <slot> Parágrafo </slot>
  </component>
</template>

<script>
/** Componente padrão de parágrafos */
export default {
  name: 'Paragraph',
  props: {
    /** Elemento que deve ser renderizado no HTML */
    element: {
      type: String,
      default: 'p'
    },
    /** Tamanho */
    size: {
      type: [String, Number],
      default: 40
    },
    /** Negrito */
    weight: {
      type: String,
      default: 'normal'
    }
  }
}
</script>

<style lang="scss" scoped>
.paragraph {
  color: $fontColor;
  margin-bottom: 10px;

  &__size-160 {
    font-size: paragraph-size('paragraph-160');
  }
  &__size-140 {
    font-size: paragraph-size('paragraph-140');
  }
  &__size-120 {
    font-size: paragraph-size('paragraph-120');
  }
  &__size-100 {
    font-size: paragraph-size('paragraph-100');
  }
  &__size-80 {
    font-size: paragraph-size('paragraph-80');
  }
  &__size-60 {
    font-size: paragraph-size('paragraph-60');
  }
  &__size-40 {
    font-size: paragraph-size('paragraph-40');
  }
  &__size-20 {
    font-size: paragraph-size('paragraph-20');
  }
  &__size-10 {
    font-size: paragraph-size('paragraph-10');
  }

  &__weight-bold {
    font-weight: font-weight('weight-bold');
  }
  &__weight-semi-bold {
    font-weight: font-weight('weight-semi-bold');
  }
  &__weight-medium {
    font-weight: font-weight('weight-medium');
  }
  &__weight-normal {
    font-weight: font-weight('weight-normal');
  }
  &__weight-light {
    font-weight: font-weight('weight-light');
  }
  &__weight-x-light {
    font-weight: font-weight('weight-x-light');
  }
  &__weight-thin {
    font-weight: font-weight('weight-thin');
  }

  &:last-child {
    margin-bottom: 0px;
  }

  strong {
    font-weight: font-weight('weight-semi-bold');
  }
}
</style>
