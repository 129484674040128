<template>
  <!-- DROPDOWN DE USUÁRIO -->
  <div class="dropdown" tabindex="0" @blur.capture="hideOptionsOnBlur">
    <!-- TÍTULO DO DROPDOWN -->
    <button
      class="dropdown--button"
      tabindex="-1"
      @click.prevent.stop="showOptions"
    >
      <slot name="button">
        Dropdown
      </slot>
      <font-awesome-icon
        class="dropdown--button__sort"
        icon="fa-solid fa-sort-down"
      />
    </button>
    <!-- DROPDOWN MENU -->
    <ul v-show="show" tabindex="-1" class="dropdown--menu">
      <slot name="items">
        <li class="dropdown--menu__link">
          <Paragraph size="40" weight="medium">Item</Paragraph>
        </li>
      </slot>
    </ul>
  </div>
</template>

<script>
/** Componente default para dropdowns */
import Paragraph from '@/components/Paragraph'

export default {
  name: 'Dropdown',
  props: {
    /** Traz o dropdown aberto ao adicionar o componente na página */
    showDropdownOnCreate: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Paragraph
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    if (this.showDropdownOnCreate) {
      this.showOptions()
    }
  },
  methods: {
    /** Mostra a lista de opções */
    showOptions() {
      this.show = true
      this.$emit('show')
    },
    /** Esconde a lista de opções */
    hideOptions() {
      this.show = false
      this.$emit('hide')
    },
    /** Esconde a lista de opções ao selecionar uma opção */
    hideOptionsOnBlur(e) {
      /** Se click foco foi para um filho mantém visível */
      if (!this.$el.contains(e.relatedTarget)) {
        this.hideOptions()
        /** Evento de blur ao clicar fora do Dropdown */
        this.$emit('blur')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: inline-block;
  position: relative;

  &--button {
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
    border: 0;
    background: initial;

    &__sort {
      color: $grayDark;
      transform: translateY(-3px);
    }
  }

  &--menu {
    background-color: $white;
    border: 1px solid $gray;
    z-index: 2;
    position: absolute;
    top: 130%;
    right: -10px;
    max-width: 100vh;
    border-radius: 5px;
    width: 180px;
    overflow: hidden;
    transition: 0.2s all;

    li {
      .paragraph {
        display: block;
        padding: 10px;
        cursor: pointer;
        transition: 0.2s all;

        &:hover {
          color: $white;
          background: $primary;
        }
      }
    }
  }
}
</style>
