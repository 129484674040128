<template>
  <div class="home">
    <AdminLayout page="Usuários">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>
        <!-- FORMULÁRIO DE CADASTRO DE USUÁRIOS -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput
            v-model="firstName"
            placeholder="Nome"
            class="form--input"
          />
          <!-- CAMPO DO SOBRENOME -->
          <FormInput
            v-model="lastName"
            placeholder="Sobrenome"
            class="form--input"
          />
          <!-- CAMPO DO E-MAIL -->
          <FormInput
            v-model="email"
            type="email"
            placeholder="E-mail"
            class="form--input"
          />
          <!-- CAMPO DA SENHA -->
          <FormInput
            v-model="password"
            type="password"
            placeholder="Senha"
            class="form--input"
          />
          <!-- CAMPO DO TIPO -->
          <FormSelect
            v-model="role"
            :options="roleOptions"
            label="label"
            placeholder="Cargos"
            class="form--input"
          />
          <!-- BOTÃO DE ENVIAR -->
          <Button :radius="false" class="form--button" @click="createUser()">
            CADASTRAR
          </Button>
        </div>
        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :hasSearch="true"
          title="Usuários cadastrados"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogUser(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>
        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE USUÁRIO -->
        <Modal
          v-model="user.delete"
          title="REMOVER USUÁRIO"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o usuário
            <strong>{{ user.firstName }}?</strong>
          </Paragraph>
          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalUser()"
            >
              CANCELAR
            </Button>
            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteUser()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

import { mapGetters } from 'vuex'

export default {
  name: 'UsersList',
  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    FormSelect,
    Button,
    Datatable,
    Modal
  },
  data() {
    return {
      /** Nome do usuário */
      firstName: null,
      /** Sobrenome do usuário */
      lastName: null,
      /** E-mail do usuário */
      email: null,
      /** Senha do usuário */
      password: null,
      /** Cargo do usuário */
      role: null,
      /** Lista de cargos para o select */
      roleOptions: [
        {
          id: 'user',
          label: 'Normal'
        },
        {
          id: 'admin',
          label: 'Administrador'
        }
      ],
      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'firstName', alias: 'Usuário', order: true },
          { name: 'email', alias: 'E-mail' },
          { name: 'role', alias: 'Cargo' },
          { name: 'action', alias: 'Ação' }
        ],
        search: null,
        data: [],
        numberElements: 0,
        numberPages: 0,
        page: 1,
        limitPerPage: 20,
        order: null,
        direction: null
      },
      /** Usuário a ser editado */
      user: {
        id: null /** ID do usuário */,
        firstName: null /** Nome do usuário */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },
  computed: {
    ...mapGetters('user', ['getId'])
  },
  created() {
    /** Busca os usuários */
    this.getUsers()
  },
  methods: {
    /** Buscar os usuários */
    getUsers() {
      this.$user
        .getAll({
          name: this.datatable.search,
          direction: this.datatable.direction,
          limitPerPage: this.datatable.limitPerPage,
          orderBy: this.datatable.order,
          page: this.datatable.page
        })
        .then((response) => {
          const users = response.data.content.map((user) => {
            return {
              id: user.id,
              firstName: `${user.firstName} ${user.lastName}`,
              dataFirstName: user.firstName,
              dataLastName: user.lastName,
              email: user.email,
              role:
                user.profiles.toString() === 'ADMIN'
                  ? 'Administrador'
                  : 'Normal'
            }
          })
          this.datatable.data = users
          this.datatable.numberElements = response.data.totalElements
          this.datatable.numberPages = response.data.totalPages
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os usuários.')
        })
    },
    /** Criar um usuário */
    createUser() {
      this.$user
        .create({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          role: this.role
        })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Usuário criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o Usuário!')
        })
        .finally(() => {
          /** Limpar o campo do nome */
          this.firstName = null
          this.lastName = null
          this.email = null
          this.password = null
          this.role = null
          /** Recarregar a lista */
          this.getUsers()
        })
    },
    /** Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getUsers()
    },
    /** Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getUsers()
    },
    /** Pesquisa da tabela
     * @param {Object} data: Objeto de dados da ordenação
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getUsers()
    },
    /** Confirmar se deseja remover o usuário
     * @param {Number} id: Id do usuário
     */
    dialogUser(id) {
      /** Localizar o usuário na listagem */
      const user = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do usuário e abrir a modal de confirmação */
      if (user) {
        this.user.delete = true
        this.user.id = user.id
        this.user.firstName = user.firstName
      } else {
        this.$toast.warning('Houve um erro ao encontrar o usuário.')
        this.closeModalUser()
      }
    },
    /** Fechar a modal de edição de usuário */
    closeModalUser() {
      this.user.delete = false
      this.user.firstName = null
    },
    /** Remover o usuário */
    deleteUser() {
      this.$user
        .delete(this.user.id)
        .then(() => {
          this.$toast.success('Usuário removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o usuário.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalUser()
          /** Recarregar a listagem */
          this.getUsers()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: center;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.list-files {
  padding-left: 15px;
  &--item {
    line-height: 20px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 50px;
      text-align: center;
    }

    thead th:nth-child(3),
    tr td:nth-child(3) {
      width: 350px;
    }

    thead th:nth-child(4),
    tr td:nth-child(4) {
      width: 150px;

      .paragraph {
        text-transform: capitalize;
      }
    }

    thead th:nth-child(5),
    tr td:nth-child(5) {
      width: 120px;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO DO CORPUS */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
