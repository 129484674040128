<template>
  <div class="filter-advanced">
    <!-- BOTÃO TOGGLE PARA ABRIR E FECHAR O FILTRO -->
    <Paragraph
      v-if="toggleButton"
      size="40"
      weight="semi-bold"
      class="filter-advanced--button"
    >
      <span @click="openFilter()"
        >Filtro avançado
        <font-awesome-icon
          :class="{ 'filter-advanced--arrow__down': !visible }"
          class="filter-advanced--arrow"
          icon="fa-solid fa-sort-down"
        />
      </span>
    </Paragraph>

    <!-- CONTEÚDO DO FILTRO -->
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="visible" class="filter-advanced--content">
        <div class="filter-advanced--combo">
          <div class="filter-advanced--combo__item">
            <Paragraph size="60" class="filter-advanced--combo__paragraph">
              Nº de palavras dentro do contexto:
            </Paragraph>

            <FormSelect
              :value="context"
              :options="contextOptions"
              :border="true"
              class="filter-advanced--select filter-advanced--select__context"
              @input="$emit('changeContext', $event)"
            />
          </div>

          <div class="filter-advanced--combo__item">
            <Paragraph size="60" class="filter-advanced--combo__paragraph">
              Situação dos documentos:
            </Paragraph>

            <div class="filter-advanced--combo__group-check">
              <FormInputCheck
                v-model="current"
                :checked="current === true"
                :inputValue="true"
                :class="{ 'filter-advanced--check__disabled': !revoked }"
                name="current"
                label="Vigentes"
                class="filter-advanced--check"
                @input="updateSituation()"
              />

              <FormInputCheck
                v-model="revoked"
                :checked="revoked === true"
                :inputValue="true"
                :class="{ 'filter-advanced--check__disabled': !current }"
                name="revoked"
                label="Revogados"
                class="filter-advanced--check"
                @input="updateSituation()"
              />
            </div>
          </div>
        </div>

        <div class="filter-advanced--combo">
          <div class="filter-advanced--combo__item">
            <Paragraph size="60" class="filter-advanced--combo__paragraph">
              Data inicial:
            </Paragraph>

            <FormInput
              :value="dateBegin"
              :border="true"
              type="date"
              class="filter-advanced--date"
              @input="$emit('changeDateBegin', $event)"
            />
          </div>

          <div class="filter-advanced--combo__item">
            <Paragraph size="60" class="filter-advanced--combo__paragraph">
              Data final:
            </Paragraph>

            <FormInput
              :value="dateEnd"
              :border="true"
              type="date"
              class="filter-advanced--date"
              @input="$emit('changeDateEnd', $event)"
            />
          </div>
        </div>

        <div class="filter-advanced--combo">
          <div class="filter-advanced--combo__item">
            <Paragraph size="60" class="filter-advanced--combo__paragraph">
              Filtrar pelos corpus dos documentos:
            </Paragraph>

            <FormSelect
              :value="corpus"
              :options="corpusOptions"
              :multiple="true"
              placeholder="Selecione o(s) corpus"
              class="filter-advanced--select filter-advanced--select__corpus"
              @input="$emit('changeCorpus', $event)"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FormSelect from '@/components/FormSelect'
import FormInput from '@/components/FormInput'
import FormInputCheck from '@/components/FormInputCheck'
import Paragraph from '@/components/Paragraph'

export default {
  name: 'FilterAdvanced',

  components: {
    FormSelect,
    FormInput,
    FormInputCheck,
    Paragraph
  },

  props: {
    /** Visibilidade do filtro */
    visible: {
      type: Boolean,
      default: false
    },

    /** Se deve haver um botão para mostrar e esconder o filtro */
    toggleButton: {
      type: Boolean,
      default: true
    },

    /** Se deve ser redimensionado */
    redimension: {
      type: Boolean,
      default: false
    },

    /** Data inicial */
    dateBegin: {
      type: [String, Date],
      default: null
    },

    /** Data final */
    dateEnd: {
      type: [String, Date],
      default: null
    },

    /** Contexto buscado */
    context: {
      type: [String, Number],
      default: null
    },

    /** Filtro de corpus */
    corpus: {
      type: Array,
      default: () => []
    },

    /** Valor filtrado do corpus pela URL */
    corpusValue: {
      type: String,
      default: null
    },

    /** Situação dos documentos */
    situation: {
      type: String,
      default: 'false'
    }
  },

  data() {
    /** Opções de contexto */
    const contextOptions = []
    for (let i = 1; i <= 10; i++) {
      contextOptions.push({
        id: i * 10,
        label: i * 10
      })
    }

    return {
      /** Opções de contexto */
      contextOptions: contextOptions,

      /** Opções de corpus */
      corpusOptions: [],

      /** Situação vigente */
      current: null,

      /** Situação revogada */
      revoked: null
    }
  },

  created() {
    /** Buscar as opções de corpus */
    this.getCorpus()

    /** Pegar a situação inicial */
    if (this.situation === 'true') {
      this.current = true
      this.revoked = false
    } else if (this.situation === 'false') {
      this.current = false
      this.revoked = true
    } else {
      this.current = true
      this.revoked = true
    }
  },

  methods: {
    /** Abrir filtro */
    openFilter() {
      const status = !this.visible
      this.$emit('changeVisible', status)
      localStorage.setItem('filter', status)
    },

    /** Buscar as opções de corpus */
    getCorpus() {
      this.$corpus
        .getAll()
        .then((response) => {
          /** Montar as opções de corpus */
          this.corpusOptions = response.data.map((corpus) => {
            return {
              value: corpus.id,
              label: corpus.name
            }
          })

          /** Ids marcados */
          const idsCorpus = this.corpusValue ? this.corpusValue.split(',') : []

          /** Marcar itens selecionados pela URL */
          const corpus = this.corpusOptions.filter((item) =>
            idsCorpus.includes(item.value.toString())
          )
          this.$emit('changeCorpus', corpus)
        })
        .catch(() => {
          this.$toast.warning(
            'Houve um erro ao apresentar as opções de corpus.'
          )
        })
    },

    /** Controla a trasição ao abrir o accordion */
    start(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    /** Controla a trasição ao fechar o accordion */
    end(el) {
      el.style.height = ''
    },

    /** Atualizar a situação */
    updateSituation() {
      /** Se os dois status estiverem ativos */
      if (this.current && this.revoked) {
        this.$emit('changeSituation', null)

        /** Se o status for vigente */
      } else if (this.current) {
        this.$emit('changeSituation', 'true')

        /** Se o status for revogado */
      } else if (this.revoked) {
        this.$emit('changeSituation', 'false')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-advanced {
  margin-top: 10px;

  &--button {
    text-align: right;
    cursor: pointer;
    color: $primary;
    display: block;
  }

  &--arrow {
    transform: rotate(180deg) translateY(-3px);

    &__down {
      transform: translateY(-2px);
    }
  }

  &--content {
    background: $transparent;
    padding: 10px 20px;
    border-radius: 15px;
    margin-top: 20px;
    transition: 0.2s all;
    max-width: $maxWidth;
    margin: 0px auto;
  }

  &--combo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 10px;

    &__paragraph {
      white-space: nowrap;
      margin-bottom: 0px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
    }

    &__group-check {
      display: flex;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  &--check {
    &__disabled {
      pointer-events: none;
    }
  }

  &--select {
    width: 100%;
    max-width: 300px;
    margin-bottom: 0px;

    &__corpus {
      width: auto;
      min-width: 170px;
    }

    @media screen and (max-width: 480px) {
      max-width: 100%;
    }

    ::v-deep {
      .form-select--field,
      .form-select--field__vselect .vs__dropdown-toggle {
        padding: 2px;
        border-color: $primary;
      }
      .vs__selected-options {
        padding: 0px 2px;
      }
    }

    &__context {
      max-width: 50px;
    }
  }

  &--date {
    width: auto;
    max-width: 100%;
    margin-bottom: 0px;

    ::v-deep {
      .form-input--field {
        padding: 2.5px;
        border-color: $primary;
      }
    }

    &__context {
      max-width: 50px;
    }
  }
}

.accordion-enter-active,
.accordion-leave-active {
  cursor: pointer;
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  cursor: pointer;
  height: 0 !important;
  opacity: 0;
}
</style>
