<template>
  <div class="datatable">
    <div class="datatable--info">
      <div>
        <!-- TÍTULO -->
        <Heading v-if="title" size="20" weight="bold" class="title">
          {{ title }}
        </Heading>

        <!-- INFORMAÇÕES -->
        <Paragraph size="40" weight="normal" class="datatable--letters">
          <strong>{{ numberElements }}</strong> {{ termResults }}
        </Paragraph>
      </div>

      <div v-if="changeVisibility" class="datatable--info__grids">
        <!-- GRID MAIOR -->
        <font-awesome-icon
          :class="{ 'datatable--grid__active': statusTable === 'colsUpper' }"
          class="datatable--grid"
          icon="fa-solid fa-table-cells"
          @click="$emit('gridTable', 'colsUpper')"
        />

        <!-- GRID MENOR -->
        <font-awesome-icon
          :class="{ 'datatable--grid__active': statusTable === 'colsLower' }"
          class="datatable--grid"
          icon="fa-solid fa-table-cells-large"
          @click="$emit('gridTable', 'colsLower')"
        />

        <!-- GRID NOWRAP -->
        <font-awesome-icon
          :class="{ 'datatable--grid__active': statusTable === 'colsNowrap' }"
          class="datatable--grid"
          icon="fa-solid fa-bars"
          @click="$emit('gridTable', 'colsNowrap')"
        />
      </div>

      <!-- CAMPOS DE BUSCA -->
      <div v-if="hasFilter || hasSearch" class="datatable--search">
        <!-- FILTRO -->
        <FormSelect
          v-if="hasFilter"
          v-model="filter"
          :border="true"
          :options="filterOptions"
          :placeholder="filterPlaceholder"
          class="filter-advanced--select"
          @input="$emit('filter', filter)"
          @blur="$emit('filter', filter)"
        />

        <!-- PESQUISA -->
        <FormInput
          v-if="hasSearch"
          v-model="search"
          :border="true"
          :iconSearch="true"
          :placeholder="searchPlaceholder"
          @search="$emit('search', search)"
          @blur="$emit('search', search)"
        />
      </div>
    </div>

    <!-- TABELA -->
    <div :class="{ datatable__loading: loading }" class="datatable__overflow">
      <table
        :class="{ 'datatable--table__nowrap': statusTable === 'colsNowrap' }"
        class="datatable--table"
      >
        <!-- COLUNAS -->
        <thead class="datatable--thead">
          <tr>
            <th v-for="item in cols" :class="item.class" :key="item.name">
              <Paragraph size="40" weight="medium" class="datatable--heading">
                {{ item.alias }}
                <!-- ORDENADOR -->
                <font-awesome-icon
                  v-if="item.order && rows.length"
                  :class="{
                    'datatable--order__desc':
                      order === item.name && direction === 'desc'
                  }"
                  class="datatable--order"
                  icon="fa-solid fa-circle-arrow-down"
                  @click="orderCallback(item.name)"
                />
              </Paragraph>
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- LINHAS -->
          <tr v-for="row in rows" :key="row.id" class="datatable--row">
            <td
              v-for="col in cols"
              :key="col.name"
              :style="col.style"
              :class="[
                { 'datatable--td__revoked': row.situation === false },
                col.class
              ]"
              class="datatable--td"
            >
              <!-- SE FOR NOWRAP - ADICIONAR TOOLTIP -->
              <vue-custom-tooltip
                v-if="col.class === 'nowrap'"
                :label="row[col.name]"
              >
                <slot :name="col.name" :value="row[col.name]" :row="row">
                  <Paragraph :style="col.style" size="60" weight="normal">
                    <span v-html="row[col.name]"></span>
                  </Paragraph>
                </slot>
              </vue-custom-tooltip>

              <!-- SE NÃO FOR NOWRAP -->
              <slot v-else :name="col.name" :value="row[col.name]" :row="row">
                <Paragraph
                  :style="col.style"
                  :class="col.class"
                  size="60"
                  weight="normal"
                >
                  <span v-html="row[col.name]"></span>
                </Paragraph>
              </slot>
            </td>
          </tr>
          <!-- SE NÃO HOUVER NENHUM DADO -->
          <tr v-if="rows && rows.length === 0" class="datatable--row">
            <td :colspan="cols.length" class="datatable--td">
              <Paragraph size="60" weight="normal">{{
                emptyMessage
              }}</Paragraph>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- NÚMERO DE ELEMENTOS PESQUISADOS -->
    <Paragraph
      v-if="loadedResults"
      size="40"
      weight="normal"
      class="datatable--letters"
    >
      <strong>{{ rows.length }}</strong
      >/{{ numberElements }} {{ termLoaded }}
    </Paragraph>

    <!-- PAGINAÇÃO -->
    <Paginate
      v-if="showPagination"
      :value="page"
      :page-count="numberPages"
      :click-handler="paginationCallback"
      :prev-text="'Anterior'"
      :next-text="'Próximo'"
      :container-class="'pagination'"
    >
    </Paginate>
  </div>
</template>

<script>
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'

/** Componente padrão de tabelas */
export default {
  name: 'Datatable',

  components: {
    Heading,
    Paragraph,
    FormInput,
    FormSelect
  },

  props: {
    /** Colunas maiores
     * @example
     * {
     *  name: 'column',
     *  alias: 'Coluna'
     * }
     */
    colsUpper: {
      type: Array,
      default: () => []
    },

    /** Colunas sem quebras de linha */
    colsNowrap: {
      type: Array,
      default: () => []
    },

    /** Colunas menores */
    colsLower: {
      type: Array,
      default: () => []
    },

    /** Linhas
     * @example
     * {
     *  column1: 'Linha',
     *  column2: 'Linha',
     *  column3: 'Linha',
     * }
     */
    rows: {
      type: Array,
      default: () => []
    },

    /** Título da tabela */
    title: {
      type: String,
      default: null
    },

    /** Se deve mudar o status de visualização da tabela (upper to lower) */
    changeVisibility: {
      type: Boolean,
      default: false
    },

    /** Se terá o campo de pesquisa */
    hasSearch: {
      type: Boolean,
      default: false
    },

    /** Placeholder do campo de pesquisa */
    searchPlaceholder: {
      type: String,
      default: 'Buscar'
    },

    /** Se terá o campo de filtro */
    hasFilter: {
      type: Boolean,
      default: false
    },

    /** Opções do campo de filtro */
    filterOptions: {
      type: Array,
      default: () => []
    },

    /** Placeholder do filtro */
    filterPlaceholder: {
      type: String,
      default: 'Filtrar'
    },

    /** Status da tabela, se está com os dados maoires ou menores */
    statusTable: {
      type: String,
      default: 'colsUpper'
    },

    /** Palavra-chave */
    query: {
      type: String,
      default: null
    },

    /** Número de elementos */
    numberElements: {
      type: Number,
      default: 0
    },

    /** Página atual */
    page: {
      type: Number,
      default: 0
    },

    /** Número de páginas */
    numberPages: {
      type: Number,
      default: 0
    },

    /** Se a tabela estiver vazia */
    emptyMessage: {
      type: String,
      default: 'Não há dados para serem apresentados.'
    },

    /** Se deve exibir a paginação */
    showPagination: {
      type: Boolean,
      default: true
    },

    /** Termo para trazer os resultados encontrados */
    termResults: {
      type: String,
      default: 'resultados encontrados'
    },

    /** Se deve apresentar o número de itens carregados */
    loadedResults: {
      type: Boolean,
      default: false
    },

    /** Termo para trazer os itens carregados */
    termLoaded: {
      type: String,
      default: 'itens carregados'
    },

    /** Se está carregando */
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      /** Ordenador */
      order: null,

      /** Direção */
      direction: 'asc',

      /** Termo pesquisado */
      search: null,

      /** Termo filtrado */
      filter: null
    }
  },

  computed: {
    cols() {
      return this[this.statusTable]
    }
  },

  methods: {
    /** Enviar emited de paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.$emit('changePagination', page)
    },

    /** Ordenação das colunas
     * @param {String} name: Identificador do item da coluna para ordenar
     */
    orderCallback(name) {
      this.order = name
      this.direction = this.direction === 'desc' ? 'asc' : 'desc'

      this.$emit('changeOrdering', {
        order: this.order,
        direction: this.direction
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.datatable {
  &__overflow {
    @media screen and (max-width: 768px) {
      overflow-x: auto;
    }
  }

  &--table {
    background: $white;
    width: 100%;
    border-radius: 10px;
    border-collapse: collapse;
    margin-bottom: 10px;
    position: relative;

    &__nowrap {
      th {
        width: 10px;

        &.nowrap {
          width: 50%;
        }
      }

      td {
        padding: 6px;

        p {
          font-size: paragraph-size('paragraph-40');
        }

        &.nowrap {
          max-width: 10px;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      @media screen and (max-width: 768px) {
        display: block;
        overflow: auto;
      }

      /** Personalização das tooltips */
      ::v-deep {
        .vue-custom-tooltip {
          text-overflow: ellipsis;
          display: block;

          &:after {
            white-space: normal;
            width: 330px;
            text-align: left;
            border-radius: 10px;
          }
        }
      }
    }
  }

  &--info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .paragraph {
      margin: 0px;
    }

    &__grids {
      display: flex;
      gap: 10px;
    }
  }

  &--search {
    display: flex;
    align-items: center;
    gap: 10px;

    .form-input,
    .form-select {
      min-width: 200px;
      margin-bottom: 0px;
    }
  }

  &--grid {
    cursor: pointer;
    color: $secondary;
    transition: 0.2 all;

    &__active {
      color: $primary;
    }
  }

  &--thead {
    th {
      padding: 10px;
      border-right: 1px solid $gray;
      cursor: pointer;
      background: $primary;
      white-space: nowrap;

      @media screen and (max-width: 768px) {
        min-width: 200px;
      }

      &:first-of-type {
        border-radius: 10px 0px 0px 0px;
      }

      &:last-of-type {
        border-radius: 0px 10px 0px 0px;
      }
    }
  }

  &--order {
    position: absolute;
    font-size: paragraph-size('paragraph-80');
    color: $white;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);

    &__desc {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &--heading {
    color: $white;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0px;
    position: relative;
  }

  &--row {
    &:nth-child(odd) {
      background-color: $secondary;
    }
  }

  &--td {
    padding: 12px;
    border-right: 1px solid $gray;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      vertical-align: baseline;
    }

    &:last-of-type {
      border-right: 0;
    }

    .paragraph {
      line-height: 20px;
      word-break: break-word;
    }

    &__revoked {
      p {
        opacity: 0.5;
      }
    }
  }

  &--description {
    line-height: 24px;
  }

  /** Carregamento */
  &__loading {
    td {
      p {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        background-color: $grayLoading;
        color: $grayLoading;
        max-width: 100%;
        max-height: 100%;
        border-radius: 3px;
        pointer-events: none;
        width: 100%;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(
            90deg,
            hsla(0, 0%, 100%, 0),
            hsla(0, 0%, 100%, 0.2) 20%,
            hsla(0, 0%, 100%, 0.5) 60%,
            hsla(0, 0%, 100%, 0)
          );
          animation: shimmer 1.2s infinite;
        }
        ::v-deep .datatable--highlight {
          background-color: inherit;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  margin: 0px auto;
  justify-content: center;

  ::v-deep {
    li {
      border: 1px solid $gray;
      border-left: 0;
      padding: 0px;
      cursor: pointer;
      font-size: paragraph-size('paragraph-60');
      color: $fontColor;

      a {
        padding: 5px 10px;
        display: block;
      }

      &.active {
        background: $primary;
        color: $white;
      }

      &:first-of-type {
        border-radius: 5px 0px 0px 5px;
        border-left: 1px solid $gray;
      }

      &:last-of-type {
        border-radius: 0px 5px 5px 0px;
      }
    }
  }
}

@keyframes shimmer {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
