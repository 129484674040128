<template>
  <component
    :is="element"
    :class="[
      `heading heading__size-${size} heading__weight-${weight}`,
      { heading__divisor: divisor }
    ]"
  >
    <slot> Título </slot>
  </component>
</template>

<script>
/** Componente padrão de títulos */
export default {
  name: 'Heading',
  props: {
    /** Elemento que deve ser renderizado no HTML */
    element: {
      type: String,
      default: 'h2'
    },
    /** Tamanho */
    size: {
      type: [String, Number],
      default: 2
    },
    /** Negrito */
    weight: {
      type: String,
      default: 'normal'
    },
    /** Divisor */
    divisor: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  margin-bottom: 10px;
  color: $fontColor;

  &__divisor {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 50px;
      height: 7px;
      border-radius: 10px;
      background: $secondary;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__size-100 {
    font-size: heading-size('heading-100');
    margin-bottom: 24px;
  }
  &__size-80 {
    font-size: heading-size('heading-80');
    font-weight: font-weight('weight-bold');
  }
  &__size-60 {
    font-size: heading-size('heading-60');
    font-weight: font-weight('weight-bold');
  }
  &__size-50 {
    font-size: heading-size('heading-50');
    font-weight: font-weight('weight-bold');
  }
  &__size-40 {
    font-size: heading-size('heading-40');
  }
  &__size-20 {
    font-size: heading-size('heading-20');
  }
  &__size-10 {
    font-size: heading-size('heading-10');
  }

  &__weight-x-bold {
    font-weight: font-weight('weight-x-bold');
  }
  &__weight-bolder {
    font-weight: font-weight('weight-bolder');
  }
  &__weight-bold {
    font-weight: font-weight('weight-bold');
  }
  &__weight-semi-bold {
    font-weight: font-weight('weight-semi-bold');
  }
  &__weight-medium {
    font-weight: font-weight('weight-medium');
  }
  &__weight-normal {
    font-weight: font-weight('weight-normal');
  }
  &__weight-light {
    font-weight: font-weight('weight-light');
  }
  &__weight-x-light {
    font-weight: font-weight('weight-x-light');
  }
  &__weight-thin {
    font-weight: font-weight('weight-thin');
  }
}
</style>
