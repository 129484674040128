<template>
  <div class="base-layout">
    <!-- CABEÇALHO -->
    <Header :redimension="$route.name === 'Search' ? true : false"></Header>
    <!-- CONTEÚDO -->
    <div class="base-layout--content">
      <div>
        <slot name="content"></slot>
      </div>
    </div>
    <!-- RODAPÉ -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/_base/Header'
import Footer from '@/components/_base/Footer'

export default {
  name: 'BaseLayout',

  components: {
    Header,
    Footer
  },

  /** Fornece os valores para o componente filho */
  provide() {
    return { searchData: this.searchData }
  },

  methods: {
    /** Pesquisa no header
     * @param { String } search - Dado pesquisado
     * @param { String|Number } context - Número de caracteres
     * @param { Array } corpus - Corpus filtrados
     * @param { String } dateBegin - Data inicial do filtro
     * @param { String } dateEnd - Data final do filtro
     */
    searchData(search, context, corpus, dateBegin, dateEnd, situation) {
      /** Informações dos filtros */
      const filters = {
        search,
        context,
        dateBegin,
        dateEnd,
        corpus,
        situation
      }

      /** Redirecionar para a tela de pesquisa */
      if (this.$route.name !== 'Search') {
        return this.$router.push({
          path: '/busca',
          query: filters
        })
      }

      /** Filtros já carregados na URL */
      const queries = this.$route.query

      /** Verificar se houve uma mudança, se houver, disparar a pesquisa */
      if (JSON.stringify(filters) !== JSON.stringify(queries)) {
        /** Atualizar os parâmetros da URL */
        this.$router.push({
          query: filters
        })

        /** Emitir o evento de pesquisa */
        return this.$emit('updateSearch', filters)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &--content {
    padding: 55px 25px;
    flex: 1;

    & > div {
      margin: 0px auto;
      max-width: $maxWidth;
      width: 100%;
    }
  }
}
</style>
