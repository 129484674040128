export default ($axios) => ({
  /** Cria um corpus
   * @param { Object } data: "name" para criar um corpus
   */
  create: (data) =>
    $axios.post('/corpus', {
      ...data
    }),

  /** Atualizar um corpus
   * @param { Number } id: "id" do corpus
   * @param { Object } data: "name" para editar um corpus
   */
  update: (id, data) =>
    $axios.put(`/corpus/${id}`, {
      ...data
    }),

  /** Busca os corpus
   * @param { Object } data: "direction" "limitPerPage" "name" "orderBy" e "page" para a consulta
   */
  get: (data) =>
    $axios.get('/corpus', {
      params: {
        ...data
      }
    }),

  /** Busca o corpus pelo id
   * @param { Object } data: "id", "direction" "limitPerPage" "name" "orderBy" e "page" para a consulta
   */
  getById: (data) =>
    $axios.get(`/corpus/${data.id}`, {
      params: {
        ...data
      }
    }),

  /** Busca os corpus (lista completa) */
  getAll: () => $axios.get('/corpus/list'),

  /** Remove um corpus */
  delete: (id = '') => $axios.delete(`/corpus/${id}`),

  /** Baixar todos os arquivos de um corpus */
  downloadAllFiles: (id = '') =>
    $axios.get(`/corpus/${id}/download`, {
      responseType: 'blob'
    })
})
