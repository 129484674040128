<template>
  <vue-topprogress
    :color="color"
    :speed="speed"
    :easing="easing"
    ref="topProgress"
  ></vue-topprogress>
</template>

<script>
/** Componente padrão da barra de progresso no topo da página */

import { vueTopprogress } from 'vue-top-progress'

export default {
  name: 'TopProgress',

  components: {
    vueTopprogress
  },

  props: {
    /** Se o elemento deve iniciar */
    start: {
      type: Boolean,
      default: false
    },

    /** Se o elemento deve encerrar */
    done: {
      type: Boolean,
      default: false
    },

    /** Velocidade da animação */
    speed: {
      type: Number,
      default: 350
    },

    /** Transição */
    easing: {
      type: String,
      default: 'linear'
    },

    /** Cor da barra */
    color: {
      type: String,
      default: '#0a5290'
    }
  },

  watch: {
    /** Detecta quando o load inicia */
    start() {
      if (this.start) this.$refs.topProgress.start()
    },

    /** Detecta quando o load finaliza */
    done() {
      if (this.done) this.$refs.topProgress.done()
    }
  },

  mounted() {
    /** Verifica se iniciou com o loader aberto */
    if (this.start) this.$refs.topProgress.start()
  }
}
</script>
