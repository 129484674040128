<template>
  <div class="home">
    <AdminLayout page="Corpus">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE CORPUS -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput
            v-model="name"
            placeholder="Nome do Corpus"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button :radius="false" class="form--button" @click="createCorpus()">
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :hasSearch="true"
          title="Corpus cadastrados"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
        >
          <!-- NÚMERO DE ARQUIVOS -->
          <template v-slot:wordlists="slotProps">
            <span
              v-if="slotProps.value > 0"
              class="datatable--link"
              @click="showWordlists(slotProps.row.id)"
            >
              <Paragraph size="60" weight="normal">
                {{ slotProps.value }}
              </Paragraph>
            </span>

            <Paragraph v-else size="60" weight="normal">
              {{ slotProps.value }}
            </Paragraph>
          </template>
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- BAIXAR OS ARQUIVOS DO WORDLIST -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-download"
                @click="downloadAllFiles(slotProps.row.id, slotProps.row.name)"
              />

              <!-- EDITAR CORPUS -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER CORPUS -->
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogCorpus(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>

        <!-- EXIBIR WORDLISTS -->
        <Modal v-model="modalVisibility" :title="modal.title">
          <Paragraph size="60" weight="normal">
            Arquivos do wordlist:
          </Paragraph>

          <!-- PERCORRER ARQUIVOS -->
          <ol class="list-files">
            <li v-for="(file, i) in modal.files" :key="i">
              <Paragraph size="60" weight="normal" class="list-files--item">
                <strong>{{ i + 1 }}.</strong> {{ file.name }};
              </Paragraph>
            </li>
          </ol>

          <!-- BOTÃO DE DOWNLOAD -->
          <Button
            class="modal-component--download"
            @click="downloadAllFiles(modal.id, modal.title)"
          >
            <font-awesome-icon icon="fa-solid fa-download" /> download
          </Button>
        </Modal>

        <!-- EDITAR CORPUS -->
        <Modal
          v-model="corpus.edit"
          title="EDITAR CORPUS"
          class="modal-edit__edit"
        >
          <!-- NOME DO CORPUS -->
          <FormInput
            v-model="corpus.name"
            :border="true"
            placeholder="Nome do Corpus"
            class="modal-edit--input"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveCorpus()"
            >
              GRAVAR
            </Button>
          </div>
        </Modal>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE CORPUS -->
        <Modal
          v-model="corpus.delete"
          title="REMOVER CORPUS"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o corpus
            <strong>{{ corpus.name }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalCorpus()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteCorpus()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

export default {
  name: 'CorpusList',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    Button,
    Datatable,
    Modal
  },

  data() {
    return {
      /** Nome do cospus */
      name: null,

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'name', alias: 'Corpus', order: true },
          { name: 'wordlists', alias: 'Arquivos' },
          { name: 'action', alias: 'Ação' }
        ],

        search: null,

        data: [],

        numberElements: 0,

        numberPages: 0,

        page: 1,

        limitPerPage: 20,

        order: null,

        direction: null
      },
      /** Visibilidade da modal */
      modalVisibility: false,

      /** Dados da modal */
      modal: {
        id: null,
        title: null,
        files: []
      },

      /** Corpus a ser editado */
      corpus: {
        id: null /** ID do corpus */,
        name: null /** Nome do corpus */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Buscar os corpus */
    this.getCorpus()
  },

  methods: {
    /** Buscar os corpus */
    getCorpus() {
      this.$corpus
        .get({
          name: this.datatable.search,
          direction: this.datatable.direction,
          limitPerPage: this.datatable.limitPerPage,
          orderBy: this.datatable.order,
          page: this.datatable.page
        })
        .then((response) => {
          const corpus = response.data.content.map((corpus) => {
            return {
              id: corpus.id,
              wordlists: corpus.wordLists.length,
              wordlistsData: corpus.wordLists,
              name: corpus.name
            }
          })
          this.datatable.data = corpus
          this.datatable.numberElements = response.data.numberOfElements
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os corpus.')
        })
    },

    /** Criar corpus */
    createCorpus() {
      this.$corpus
        .create({ name: this.name })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Corpus criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o Corpus!')
        })
        .finally(() => {
          /** Limpar o campo do nome */
          this.name = null
          /** Recarregar a lista */
          this.getCorpus()
        })
    },

    /** Baixar todos os wordlists de um corpus
     * @param {Number|String} id: ID do corpus
     */
    downloadAllFiles(id = null, title = 'arquivos') {
      this.$corpus
        .downloadAllFiles(id)
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', `${title}.zip`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao fazer o download dos arquivos!')
        })
    },

    /** Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getCorpus()
    },

    /** Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getCorpus()
    },

    /** Pesquisa da tabela
     * @param {Object} data: Objeto de dados da ordenação
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getCorpus()
    },

    /** Exibir os arquivos do corpus
     * @param {Number} id: Id do corpus
     */
    showWordlists(id) {
      const corpus = this.datatable.data.find((item) => item.id === id)
      if (corpus) {
        this.modal.id = corpus.id
        this.modal.title = corpus.name
        this.modal.files = corpus.wordlistsData
        this.modalVisibility = true
      } else {
        this.$toast.warning('Houve um erro ao encontrar o corpus.')
        this.closeModalCorpus()
      }
    },

    /** Exibir a modal de edição de corpus
     * @param {Number} id: Id do corpus
     */
    edit(id) {
      /** Localizar o corpus na listagem */
      const corpus = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do corpus e abrir a modal */
      if (corpus) {
        this.corpus.edit = true
        this.corpus.id = corpus.id
        this.corpus.name = corpus.name
      } else {
        this.$toast.warning('Houve um erro ao encontrar o corpus.')
        this.closeModalCorpus()
      }
    },

    /** Confirmar se deseja remover o corpus
     * @param {Number} id: Id do corpus
     */
    dialogCorpus(id) {
      /** Localizar o corpus na listagem */
      const corpus = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do corpus e abrir a modal de confirmação */
      if (corpus) {
        this.corpus.delete = true
        this.corpus.id = corpus.id
        this.corpus.name = corpus.name
      } else {
        this.$toast.warning('Houve um erro ao encontrar o corpus.')
        this.closeModalCorpus()
      }
    },

    /** Fechar a modal de edição de corpus */
    closeModalCorpus() {
      this.modal.id = null
      this.modal.title = null
      this.modal.files = []
      this.corpus.edit = false
      this.corpus.delete = false
      this.corpus.id = null
      this.corpus.name = null
      this.modalVisibility = false
    },

    /** Buscar os corpus */
    saveCorpus() {
      this.$corpus
        .update(this.corpus.id, { name: this.corpus.name })
        .then(() => {
          this.$toast.success('Corpus atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o corpus.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalCorpus()
          /** Recarregar a listagem */
          this.getCorpus()
        })
    },

    /** Remover o corpus */
    deleteCorpus() {
      this.$corpus
        .delete(this.corpus.id)
        .then(() => {
          this.$toast.success('Corpus removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o corpus.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalCorpus()
          /** Recarregar a listagem */
          this.getCorpus()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: center;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.list-files {
  padding-left: 15px;
  margin-bottom: 10px;

  &--item {
    line-height: 20px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 50px;
      text-align: center;
    }

    thead th:nth-child(3),
    tr td:nth-child(3) {
      width: 120px;
      text-align: center;
    }

    thead th:nth-child(4),
    tr td:nth-child(4) {
      width: 120px;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO DO CORPUS */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
