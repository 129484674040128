<template>
  <div class="label">
    <span class="label--link" @click="redirect(term)">
      <Paragraph size="60" weight="normal">{{ term }}</Paragraph>
    </span>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

/** Componente padrão de labels */
export default {
  name: 'Label',

  components: {
    Paragraph
  },

  props: {
    /** Termo */
    term: {
      type: String,
      default: null
    }
  },

  methods: {
    /** Redirecionar para o filtro do termo selecionado */
    redirect(term = '') {
      /** Fechar o filtro avançado */
      localStorage.setItem('filter', false)

      /** Redicionar para a tela de pesquisa */
      return this.$router.push({
        path: '/busca',
        query: {
          search: term
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  background: $secondary;
  border-radius: 50px;
  text-align: center;

  display: inline-block;

  &--link {
    display: block;
    padding: 10px 20px;
  }

  .paragraph {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
