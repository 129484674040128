<template>
  <div class="form-input">
    <label v-if="label" :for="`form-input-${id}`" class="form-input--label">
      <Paragraph size="40" weight="medium">{{ label }}</Paragraph>
    </label>

    <!-- SE NÃO TIVER MÁSCARA -->
    <input
      v-if="!hasMask"
      :value="value"
      :placeholder="placeholder"
      :type="type"
      :id="`form-input-${id}`"
      :ref="`form-input-${id}`"
      :class="{
        'form-input--field__radius': radius,
        'form-input--field__border': border
      }"
      class="form-input--field"
      @keyup.enter="search()"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
    />

    <!-- SE TIVER MÁSCARA -->
    <the-mask
      v-if="hasMask"
      :masked="true"
      :mask="mask"
      :id="`form-input-${id}`"
      :ref="`form-input-${id}`"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :class="{
        'form-input--field__radius': radius,
        'form-input--field__border': border
      }"
      class="form-input--field"
      @keyup.enter="$emit('search', value)"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
    >
    </the-mask>

    <!-- SE DEVE HAVER O ÍCONE DE PESQUISA -->
    <span v-if="iconSearch" class="form-input--search" @click="search()">
      <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="lg" />
    </span>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

import { TheMask } from 'vue-the-mask'

export default {
  name: 'FormInput',

  components: {
    TheMask,
    Paragraph
  },

  props: {
    /** Tipo de input */
    type: {
      type: String,
      default: 'text'
    },

    /** Se terá máscara no campo de input */
    hasMask: {
      type: Boolean,
      default: false
    },

    /** Máscara que será usada no campo */
    mask: {
      type: [Array, String],
      default: ''
    },

    /** Label do campo */
    label: {
      type: String,
      default: ''
    },

    /** Placeholder do campo */
    placeholder: {
      type: String,
      default: 'Insira o conteúdo'
    },

    /** Nome do campo */
    name: {
      type: String,
      default: ''
    },

    /** Valor do campo */
    value: {
      type: [String, Number, Object, Array],
      default: ''
    },

    /** Ícone de pesquisa */
    iconSearch: {
      type: Boolean,
      default: false
    },

    /** Maior arredondamento do campo de input */
    radius: {
      type: Boolean,
      default: false
    },

    /** Se o input deve ter borda */
    border: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      id: this._uid /** Id único do componente */
    }
  },

  methods: {
    /** Disparar evento de pesquisa */
    search() {
      /** Enviar valor para o componente pai */
      this.$emit('search', this.value)
      /** Remover foco */
      const input = this.$refs[`form-input-${this.id}`]
      input.blur()
    }
  }
}
</script>

<style lang="scss" scoped>
.form-input {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;

  &--field {
    background: $white;
    padding: 8px 20px;
    color: $fontColor;
    width: 100%;
    border: 0;
    font-size: paragraph-size('paragraph-60');
    line-height: paragraph-size('paragraph-60');
    font-weight: font-weight('weight-normal');
    border-radius: 5px;

    &::placeholder,
    &::-webkit-datetime-text,
    &::-webkit-datetime-month-field,
    &::-webkit-datetime-day-field,
    &::-webkit-datetime-year-field {
      filter: grayscale(100%);
      color: $fontColor;
      opacity: 0.7;
    }

    &:focus {
      outline: none;
    }

    &__radius {
      border-radius: 50px;
    }

    &__border {
      border: 1px solid $gray;
    }
  }

  &--search {
    border: 0;
    position: absolute;
    top: 0px;
    right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $primary;
  }

  &--label {
    margin-bottom: 5px;

    .paragraph {
      margin-bottom: 0px;
    }
  }
}
</style>
