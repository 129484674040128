<template>
  <div class="form-input-check">
    <input
      :id="`form-input-check-${id}`"
      :name="name"
      :value="inputValue"
      :checked="checked"
      type="checkbox"
      @change="eventEmmiter"
    />

    <label :for="`form-input-check-${id}`" class="form-input-check--label">
      <Paragraph size="60">
        {{ label }}
      </Paragraph>
    </label>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

/** Componente de input do tipo checkbox */
export default {
  name: 'FormInputCheck',

  components: {
    Paragraph
  },

  props: {
    /** Valor do input */
    inputValue: [String, Number, Object, Boolean],

    /** Nome do input */
    name: String,

    /** Label do input */
    label: String,

    /** Se está selecionado */
    checked: {
      type: [String, Boolean],
      default: false
    }
  },

  data() {
    return {
      id: this._uid /** Id único do componente */
    }
  },

  computed: {
    compId() {
      return this.id || this.defaultId
    }
  },

  methods: {
    eventEmmiter(e) {
      const currentValue = e.target.checked

      this.$emit('input', currentValue ? this.inputValue : null)
      this.$emit('change', currentValue ? this.inputValue : null)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-input-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 5px;

  &--label {
    margin-bottom: 0px;
    cursor: pointer;
  }
}
</style>
